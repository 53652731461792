import {createMuiTheme} from '@material-ui/core/styles';
import {contextPath, contextBaseUri,publicURL} from "./contextPath";


const defaultThemeData = {
    palette: {
        primary: {
            main: '#26a69a'
        },
        secondary: {
            main: 'rgba(38,166,154,0.1)'
        }
    },
}

const defaultTheme = createMuiTheme(defaultThemeData);
async function themePromiseFN(themeName){
    try {
        const theme_rsp = await fetch(`${publicURL}themes/theme_${themeName}.json`)
        const theme_json = await theme_rsp.json();
        const theme = createMuiTheme(theme_json);

        if (theme_json.customProps){
            Object.defineProperty(theme,'customProps',theme_json.customProps);
        }

        return theme;

    }catch (e){
        console.error("While fetching theme got",e);
        return defaultTheme;
    }

}

export default themePromiseFN;





