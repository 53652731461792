import React, {useState, useCallback, useRef, useEffect, useContext} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import JourneyCard from "../components/runJourneyComponents/JourneyCard";
import {Journey} from "./Journey";
import axios from "axios";
import {instanceUrl, tasksUrl} from "../variables";
import {contextPath} from "../contextPath";
import {getStore} from "sancus-client-common/dist/store";
import {AlertContext} from "../context/alert/alertContext";

export const RunJourneys = (props) => {
    const [journeys,setJourneys] = useState([]);
    const {showAlert} = useContext(AlertContext);

    const routerProcessInstanceId = props.match.params.processInstanceId || '';
    let routerJourneyName = props.match.params.journeyName || '';

    const history = useHistory();
    const enteredProcessInstanceIdRef = useRef(routerProcessInstanceId);
    const enteredTaskIdRef = useRef('');
    const auditTaskIdForReviewRef = useRef('');

    let defaultPage = 'dashboard';
    if (routerJourneyName && routerProcessInstanceId) {
        defaultPage = routerJourneyName;
    }

    const [currentPage, setCurrentPage] = useState(defaultPage);

    function deleteInstancePromise() {
        if (enteredProcessInstanceIdRef.current) {
            return axios({
                method: 'delete',
                url: `${instanceUrl}/${enteredProcessInstanceIdRef.current}?taskId=${enteredTaskIdRef.current}&reason=Requested By User`,
            })
                .then((response) => {
                    console.log('Instance was deleted with API: ', response);
                })

        } else {
            return Promise.resolve();
        }
    }

    useEffect(()=>{
        axios.get(`${contextPath}api/processmgmt?category=journey`).then(rsp=>{
            const processDefinitions = rsp.data;
            const journeys=  processDefinitions.map(pd=>{
                return {
                    name:pd.key,
                    title:pd.name
                }
            })
            setJourneys(journeys)
        },error => showAlert(error, 'error'));

        const onReduxStoreDataChange = () => {
            const auditTaskForReview = getStore().getState().auditTaskForReview;

            if (auditTaskForReview && auditTaskForReview !== auditTaskIdForReviewRef.current) {
                console.log("Run Journey: Redux auditTaskForReview change triggered", auditTaskForReview);
                auditTaskIdForReviewRef.current = auditTaskForReview;

                deleteInstancePromise()
                    .catch(e => console.error(e))
                    .finally(() => {
                        return axios({
                            method: 'post',
                            url:  `${tasksUrl}/${auditTaskForReview}?delegate`,
                            params: {
                                delegate: null,
                            }
                        })
                            .catch(e => console.error(e))
                            .finally(() => {
                                history.push(`/tasks/${auditTaskForReview}`)
                            })
                    })
            }
        };


        // Redux store
        const store = getStore();
        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);

        // code here work like in componentWillUnmount()
        return () => {
            unsubscribeStore();

            // set null setAuditTaskForReview
            const setAuditTaskForReview = (payload) => {
                return {
                    type: 'SET_TASK_FOR_REVIEW',
                    payload: payload,
                }
            };

            deleteInstancePromise().catch(e => console.error(e));

            getStore().dispatch(setAuditTaskForReview(null));
        }
    },[]);

    function showJourneyDashboard() {
        setCurrentPage('dashboard');
        enteredProcessInstanceIdRef.current = '';
        enteredTaskIdRef.current = '';
        history.push(`/run_journeys`);
    }

    const onCloseCBK = useCallback(async () => {
        deleteInstancePromise().catch(e => console.error(e));
        showJourneyDashboard();
    }, []);

    const instanceExitCbk = useCallback((pageKey) => (instanceStatus)=>{
        const {state,processInstanceId,endStates} = instanceStatus;
        if (pageKey !== "query_audit" && pageKey !== "search_customer") {
            if (state?.indexOf("TERMINATED") !== -1){
                showAlert('Process was terminated', 'warning');
            }else{
                if (endStates[0]){
                    const documentation = endStates[0].documentation || "Success!"
                    const alertType=  endStates[0].attributes?.state || 'success'
                    showAlert(endStates[0]?.documentation, alertType);
                }else {
                    showAlert('Success!', 'success');
                }
            }


        }
        showJourneyDashboard();
    }, []);

    const instanceEnterCbk = useCallback((currentPage) => (enterProcessInstanceId) => {
        console.log(`RunJourneys enterProcessInstanceId = ${enterProcessInstanceId}`);

        if (enterProcessInstanceId !== enteredProcessInstanceIdRef.current) {
            enteredProcessInstanceIdRef.current = enterProcessInstanceId;
            history.push(`/run_journeys/${currentPage}/${enterProcessInstanceId}`);
        }
    }, []);


    const taskEnterCbk = useCallback(enterTaskId => {
        console.log(`RunJourneys enterTaskId = ${enterTaskId}`);
        enteredTaskIdRef.current = enterTaskId;
    }, []);

    let renderedPage;
    const journey = journeys.find(j=>j.name === currentPage);
    if (journey){
        renderedPage = (<Journey
            processInstanceId={routerProcessInstanceId}
            processDefinitionKey={currentPage}
            title={journey.title}
            onCloseCBK={onCloseCBK}
            taskEnterCbk={taskEnterCbk}
            instanceExitCbk={instanceExitCbk(currentPage)}
            instanceEnterCbk={instanceEnterCbk(currentPage)}
        />);
    }else{
        renderedPage = (
            <Grid id="services" style={{padding: 40}}>
                <Grid container spacing={5} >
                    {journeys.map((j, index) => {
                        let img;
                        try{
                            img = require(`../assets/${j.name}.svg`);
                        }catch (e){
                            img = require(`../assets/service_default_icon.svg`);
                        }
                        return <Grid item xs={3} key={j.name + index}>
                            <JourneyCard
                                cardData={{title: j.title, img: img, name: j.name}}
                                onCardClick={() => setCurrentPage(j.name)}/>
                        </Grid>
                    })}
                </Grid>
            </Grid>
        );
    }
    return renderedPage;
};
