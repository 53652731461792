import React, {useEffect, useRef} from 'react';
import Grid from "@material-ui/core/Grid/index";
import {useTheme} from "@material-ui/core/styles";

import Chart from "chart.js"

export default function LineChart({config}) {
    const canvasRef = useRef(null);
    console.log(`LineChart data:`, config);

    const theme = useTheme();

    useEffect(() => {
        const myChart = new Chart(canvasRef.current, {
            type: 'line',
            data: {
                labels: config.labels,
                datasets: [{
                    backgroundColor: config.fillColor || theme.palette.secondary.main,
                    borderColor: config.borderColor || theme.palette.primary.main,
                    data: config.data,
                    // label: '',
                    fill: 'start'
                }],
            },
            options: {
                // maintainAspectRatio: false,
                legend: {
                    display: false
                },
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                        }
                    }],
                },
            }
        });
    }, []);


    return (
        <Grid>
            <Grid item xs={12} style={{padding: 30}}>
                <canvas ref={canvasRef}/>
            </Grid>
        </Grid>);
}
