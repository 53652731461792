import React, {useReducer} from 'react';
import {GroupsContext} from './GroupsContext';
import {GroupsReducer} from './GroupsReducer';
import {STORE_FETCHED_GROUPS} from '../types'

export const GroupsState = ({children}) => {
    const initialState = [];
    const [state, dispatch] = useReducer(GroupsReducer, initialState);

    function storeFetchedGroups(data) {
        console.log(`storeFetchedGroups triggered`, data);

        dispatch({
            type: STORE_FETCHED_GROUPS,
            payload: data
        })
    }

    return (
        <GroupsContext.Provider value={{
            storeFetchedGroups,
            GroupsState: state
        }}>
            {children}
        </GroupsContext.Provider>
    )
};
