import React, {useReducer} from 'react';
import {LoggedUserContext} from './loggedUserContext';
import {loggedUserReducer} from './loggedUserReducer';
import {LOGGED_OUT, LOGGED_IN} from '../types'

export const LoggedUserState = ({children}) => {
    const initialState = {
        isLoggedIn: null,
        user: {}
    };

    const [state, dispatch] = useReducer(loggedUserReducer, initialState);

    function setUserLoggedIn(data) {
        console.log(`setUserLoggedIn triggered`, data);

        dispatch({
            type: LOGGED_IN,
            payload: data
        })
    }

    function setUserLoggedOut() {
        console.log(`setUserLoggedOut triggered, current state`, state);

        // if (!state.isLoggedIn) {
        //     return;
        // }

        dispatch({
            type: LOGGED_OUT,
            payload: {}
        })
    }

    return (
        <LoggedUserContext.Provider value={{
            setUserLoggedIn, setUserLoggedOut,
            LoggedUserState: state
        }}>
            {children}
        </LoggedUserContext.Provider>
    )
};
