/* eslint-disable no-use-before-define */

import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from "@material-ui/core/Chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleAutocomplete({label, choices, groupIds, valueChangeCbk}) {
    const [ value, setValue ] = useState(groupIds || []);
    const labelForChoice = choices.reduce((acc,c)=>{
        if (c.name && c.name !== c.id){
            acc[c.id] = c.name;
        }
        return acc;
    },{});

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={choices}
            disableCloseOnSelect
            getOptionLabel={(option) => {
                return option.name || option.label || option.value || option.title
            }}
            getOptionSelected={(option, value) => {
                return option.id === value.id;
            }}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name || option.label || option.value || option.title}
                </React.Fragment>
            )}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <Chip
                        label={labelForChoice[option.value] || option.id || option.value}
                        {...getTagProps({index})}
                    />
                ))
            }}
            onChange={(event, newValue) => {
                setValue(newValue.map(v => v.id));
                valueChangeCbk(newValue.map(v => v.id));
            }}
            value={value.map(v => ({value: v, id: v}))}

            renderInput={(params) => (
                 <TextField {...params} variant="filled" label={label} margin={"normal"}/>
            )}
        />
    );
}
