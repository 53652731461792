import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import {AlertContext} from "../context/alert/alertContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

// this is customized Snackbars, CustomizedAlert as better component name
export default function CustomizedAlert() {
    const classes = useStyles();
    const {showAlert, hideAlert, alert} = useContext(AlertContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        hideAlert();
        if (alert.resolve){
            alert.resolve();
        }
    };

    return (
        <div className={classes.root}>
            <Snackbar open={alert.visible} autoHideDuration={4000} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity={alert.type}  elevation={10} variant="filled">
                    {alert.text}
                </Alert>
            </Snackbar>
            {/*<Alert severity="error">This is an error message!</Alert>
            <Alert severity="warning">This is a warning message!</Alert>
            <Alert severity="info">This is an information message!</Alert>
            <Alert severity="success">This is a success message!</Alert>*/}
        </div>
    );
}
