import {FormControl, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {},
    select: {
        "&": {
            fontSize: '13px',
            fontWeight: '400'
        },
        "& :focus": {
            background: 'transparent'
        }

    }
}));

export default function FilterSelect({valueNamesDictiony = {}, defaultValue, selectCallback}) {
    const classes = useStyles();
    const [age, setAge] = React.useState(defaultValue);

    const handleChange = (event) => {
        if (selectCallback) {
            selectCallback(event.target.value);
        }
        setAge(event.target.value)
    };

    return (
        <FormControl className={classes.formControl}>
            <Select className={classes.select}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={age}
                onChange={handleChange}
                disableUnderline>
                {Object.keys(valueNamesDictiony).map((filterValue) => {
                    return <MenuItem value={filterValue} key={filterValue}>{valueNamesDictiony[filterValue]}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}
