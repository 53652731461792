import React, { useState, useEffect, useContext } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {blackButtonStyles, usersUrl} from "../variables";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {TablePaginationActions} from "./UserManagement";
import groupManagementServiceFactory from "../services/groupManagement.service";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField/TextField";
import {AlertContext} from "../context/alert/alertContext";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },

    }
});

const groupManagementService = groupManagementServiceFactory();

const CreateGroupDialog = ({openCreateDialog, setOpenCreateDialog, onCreateGroup}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ name, setName ] = useState('');
    const [ groupId, setGroupId ] = useState('');
    const [ groupIdError, setGroupIdError ] = useState('');

    useEffect(() => {
        //clear if dialog is not visible.
        if(!openCreateDialog) {
            setName('');
            setGroupId('');
        }
    }, [openCreateDialog]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await groupManagementService.createGroup({name, groupId});
            onCreateGroup(result);
            showAlert(`group ${result.name} created successfully`, 'success');
            setOpenCreateDialog(false);
        }
        catch (e) {
            console.error(e);
            if (e.response && e.response.status === 409) {
                setGroupIdError('Group with such id already exists');
            } else {
                showAlert(`Unexpected error`, 'error');
            }
        }
    };

    return (
        <Dialog
            open={openCreateDialog}
            onClose={() => {
                setOpenCreateDialog(false);
            }}
        >
            <DialogTitle>{"Create group"}</DialogTitle>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <TextField fullWidth variant="outlined"
                                       margin={"normal"}
                                       label={"Group id"}
                                       required={true}
                                       value={groupId}
                                       onChange={(evt) => {
                                           setGroupId(evt.target.value);
                                       }}
                                       error={!!groupIdError}
                                       helperText={groupIdError}
                            />
                            <TextField fullWidth variant="outlined"
                                       margin={"normal"}
                                       label={"Group name"}
                                       required={true}
                                       value={name}
                                       onChange={(evt) => {
                                           setName(evt.target.value);
                                       }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={() => {
                            setOpenCreateDialog(false);
                        }} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                        <Button variant="contained" color="primary" autoFocus type="submit">
                            Add Group
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const EditGroupDialog = ({group, openDialog, closeDialog, onEditGroup}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ name, setName ] = useState(group.name);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await groupManagementService.editGroup({
                id: group.id,
                name,
            });
            if(result) {
                onEditGroup(result);
                //closeDialog();
                showAlert(`group ${result.name} edited successfully`, 'success');
            }
            else {
                showAlert(`Could not edit`, 'error');
            }
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error`, 'error');
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Edit group ${group.name}`}</DialogTitle>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <TextField fullWidth variant="outlined"
                                       margin={"normal"}
                                       label={"Group id"}
                                       required={true}
                                       value={group.id}
                                       disabled={true}
                            />
                            <TextField fullWidth variant="outlined" margin={"normal"}
                                       label={"Group name"}
                                       required={true}
                                       value={name}
                                       onChange={(evt) => {
                                           setName(evt.target.value);
                                       }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={() => {
                            closeDialog();
                        }} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                        <Button variant="contained" color="primary" autoFocus type="submit">
                            Edit Group
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const DeleteGroupDialog = ({group, openDialog, closeDialog, onDeleteGroup}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ cascadeDel, setCascadeDel ] = useState(false);
    const [willBeDeletedUsers, setWillBeDeletedUsers] = useState(null);

    const handleSubmit = async () => {
        try {
            const result = await groupManagementService.deleteGroup({
                id: group.id,
                cascade: cascadeDel,
            });
            if(result) {
                onDeleteGroup(group.id);
                setWillBeDeletedUsers(null);
                setCascadeDel(false);
                //closeDialog();
                showAlert(`group ${group.name} deleted successfully`, 'success');
            }
            else {
                showAlert(`Could not delete`, 'error');
            }
        }
        catch (e) {
            console.error(e);
            if( e.response && e.response.status === 409){
                setWillBeDeletedUsers(e.response.data);
            } else {
                showAlert(`Unexpected error`, 'error');
            }
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Delete group ${group.name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deleting a group cannot be undone!
                </DialogContentText>

                {willBeDeletedUsers ? (
                    <Grid container direction={'row'}>

                        <Grid item>
                            <Typography>To delete this group you have to delete group's users, <strong>which belong to this only group. </strong> </Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox value={cascadeDel}
                                              color="primary"
                                              onChange={(evt) => {
                                        setCascadeDel(evt.target.checked);
                                    }}
                                    />
                                }
                                label="Delete group's users"
                            />

                        </Grid>

                            <List>
                                {

                                }
                            </List>
                        {
                            willBeDeletedUsers.map((user) => (
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src={user.imageURL || `${usersUrl}/${user.id}/image`}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ (user.firstName || '') + ' ' + (user.lastName || '')}
                                        secondary={null}
                                    />
                                </ListItem>
                            ))
                        }
                    </Grid>) : <></>
                }

            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Box pr={1}>
                <Button onClick={() => {
                    closeDialog();
                }} color="primary">
                    Cancel
                </Button>
                </Box>
                <Box p={2}>
                <Button variant="contained" color="primary" autoFocus onClick={handleSubmit}>
                    Delete Group
                </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const GroupManagement = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { showAlert } = useContext(AlertContext);

    const pageSizeOptions = [5, 10, 25, {label: 'All', value: -1}];
    const [ pageSize, setPageSize ] = useState(pageSizeOptions[0]);
    const [ page, setPage ] = useState(0);
    const [ itemsCount, setItemsCount ] = useState(0);
    const [ groups, setGroups ] = useState([]);

    const fetchAllGroups = async () => {
        try {
            const result = await groupManagementService.fetchGroups();
            setGroups(result);
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error: could not fetch groups`, 'error');
        }
    };

    useEffect(() => {
        fetchAllGroups();
    }, []);

    useEffect(() => {
        //calculate pagination
        if(groups.length > 0) {
            setItemsCount(groups.length);
        }
    }, [groups, pageSize]);

    const handleChangePage = (evt, page) => {
        console.debug(`set page: ${page}`);
        setPage(page);
    };

    const handlePageSizeChange = (evt) => {
        console.debug(`set page size parsed value: ${evt.target.value}`);
        const numValue = isNaN(parseInt(evt.target.value)) ? pageSizeOptions[0] : parseInt(evt.target.value);
        console.debug(`set page size: ${numValue}`);
        setPageSize(numValue);
    };

    const pageFilter = (group, index) => {
        return pageSize < 0
            ? true
            : index < pageSize * (page + 1) && index >= page * pageSize
                ? true
                : false;
    }

    const [ openCreateDialog, setOpenCreateDialog ] = useState(false);

    const onCreateGroup = (group) => {
        console.debug(`new group: ${group}`);
        setGroups([
            ...groups, {
                ...group,
            },
        ]);
    };

    const onEditGroup = (group) => {
        console.debug(`edit group:`);
        console.debug(group);
        handleCloseEditDialog();
        setTimeout(() => {
            setGroups(groups.map(g => g.id === group.id ? ({...group}) : g));
        });
    };

    const onDeleteGroup = (groupId) => {
        console.debug(`delete group: ${groupId}`);
        handleCloseDeleteDialog();
        setTimeout(() => {
            setGroups(groups.filter(g => g.id !== groupId));
        });
        //TODO handle set page if all page items deleted!
    }

    const [ openEditDialog, setOpenEditDialog ] = useState(false);
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ editOrDeleteItem, setEditOrDeleteItem ] = useState(null);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        handleCloseEditMenu();
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        handleCloseEditMenu();
    }

    const handleCloseEditMenu = () => {
        setAnchorEl(null);
        setEditOrDeleteItem(null);
        console.debug('clear edit/delete item');
    };

    const handleOpenEditMenuFactory = (group) => {
        return (evt) => {
            console.debug('edit delete item:')
            console.debug(group);
            setEditOrDeleteItem(group);
            setAnchorEl(evt.currentTarget);
        };
    };

    return (<>
        <Box p={4}>
            <Grid container direction="row" justify="space-between"  alignItems="center">
                <Grid item>
                    <Box pb={3} pl={2}>
                        <Typography variant={'h6'}>Groups</Typography>
                    </Box>
                </Grid>

                <Grid className={classes.buttonContainer}>
                    <Box pb={3}>
                        <Button variant="contained" color="primary" onClick={() => {
                            setOpenCreateDialog(true);
                        }}>Add group</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer component={Paper} elevation={0} square={true} style={{ borderBottom: '0px'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>NAME</TableCell>
                                <TableCell>USERS</TableCell>
                                <TableCell align={'right'}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                groups.filter(pageFilter).map((group, index) => {
                                    return (
                                        <TableRow key={`${index}-${group.id}-${group.name}`} className={classes.root}>
                                            <TableCell>
                                                <Typography variant={'body1'}>{group.id}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>{group.name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>{group.users}</Typography>
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <IconButton onClick={handleOpenEditMenuFactory(group)}>
                                                    <MoreHorizIcon color="primary"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={pageSizeOptions}
                                    colSpan={4}
                                    count={itemsCount}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handlePageSizeChange}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>

            <CreateGroupDialog openCreateDialog={openCreateDialog} setOpenCreateDialog={setOpenCreateDialog} onCreateGroup={onCreateGroup}/>
            {
                editOrDeleteItem && <EditGroupDialog group={editOrDeleteItem} openDialog={openEditDialog && !!editOrDeleteItem} closeDialog={handleCloseEditDialog} onEditGroup={onEditGroup}/>
            }
            {
                editOrDeleteItem && <DeleteGroupDialog group={editOrDeleteItem} openDialog={openDeleteDialog && !!editOrDeleteItem} closeDialog={handleCloseDeleteDialog} onDeleteGroup={onDeleteGroup}/>
            }
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseEditMenu}
            >
                <MenuItem onClick={() => {
                    setOpenEditDialog(true);
                }}>Edit</MenuItem>
                <MenuItem onClick={() => {
                    setOpenDeleteDialog(true);
                }}>Delete</MenuItem>
            </Menu>
        </Box>
        </>
    );
};

export default GroupManagement;
