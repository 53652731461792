import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {AlertContext} from "../context/alert/alertContext";
import axios from "axios";
import {blackButtonStyles, usersMgmtUrl, usersUrl} from "../variables";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CreateUserDialog from "../components/CreateUserDialog";
import moment from "moment";
import {ServerConfigContext} from "../context/serverConfig/serverConfigContext";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },

    },
    buttonContainer: blackButtonStyles,
});


export function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const useStyles2 = makeStyles((theme) => ({
    tableContainer: {
        borderBottom: '0px'
    },
    table: {
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export default function UserManagement() {
    const [shownUsers, setShownUsers] = useState(null);
    const [groupsForAssign, setGroupsForAssign] = useState([]);
    const {showAlert} = useContext(AlertContext);
    const {ServerConfigState} = useContext(ServerConfigContext);
    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (shownUsers || []).length - page * rowsPerPage);
    const currentUserRef = useRef(null);

    // delete user dialog
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleDeleteDialogClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleUnlockUser = (userId)=>{
        axios({
            method: 'put',
            url: `${usersMgmtUrl}/${userId}/unlock`,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    getAllCamundaUsers();
                },
                error => showAlert(error, 'error'));
    };

    const handleSuspendUser = (userId) => {
        axios({
            method: 'put',
            url: `${usersMgmtUrl}/${userId}/suspend`,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    getAllCamundaUsers();
                },
                error => showAlert(error, 'error'));
    };

    const handleRestoreUser = (userId) => {
        axios({
            method: 'put',
            url: `${usersMgmtUrl}/${userId}/restore`,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    getAllCamundaUsers();
                },
                error => showAlert(error, 'error'));
    };

    const deleteUser = () => {
        axios({
            method: 'delete',
            url: `${usersMgmtUrl}/${currentUserRef.current.id}`,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    handleDeleteDialogClose();
                    getAllCamundaUsers();
                },
                error => showAlert(error, 'error'));
    };

    const handleResetPassword = () => {
        axios({
            method: 'post',
            url: `${usersMgmtUrl}/forgot-password/resetPasswordToDefault/${currentUserRef.current.id}`,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    getAllCamundaUsers();
                },
                error => showAlert(error, 'error'));
    };


    // Create update user dialog
    const [createDialogState, setCreateDialogState] = useState(false);
    const [createDialogUser, setCreateDialogUser] = useState(null);

    const createDialogClose = () => {
        setCreateDialogState(false);
        setCreateDialogUser({});
    };

    const createOrUpdateUserCompleted = (data) => {
        handleDeleteDialogClose();
        createDialogClose();
        getAllCamundaUsers();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function randomPresent() {
        return !!Math.round(Math.random())
    }

    function getAllCamundaUsers() {
        axios({
            method: 'get',
            url: `${usersMgmtUrl}/`,
        })
            .then((response) => {
                    setShownUsers(
                        response.data.map((user) => ({
                            ...user,
                            imageURL: user.imageURL || `${usersUrl}/${user.id}/image`,
                            // TODO this is only for demo purposes, delete after fix backend
                            lastActivity: user.lastActivity,
                            roles: user.roles ,
                            groups: user.groups,
                            lastLoginAt: user.lastLoginAt && moment(user.lastLoginAt),
                            lastLogoutAt: user.lastLogoutAt && moment(user.lastLogoutAt),
                            createdAt:user.createdAt && moment(user.createdAt),
                            updatedAt:user.updatedAt && moment(user.updatedAt),
                            azureUser: user.azureUser,
                        }))
                    );
                },
                error => showAlert(error, 'error'));
    }

    function getGroupsForAssign() {
        axios({
            method: 'get',
            url: `${usersMgmtUrl}/sancus-groups`,
        })
            .then((response) => {
                    console.log(`getGroupsForAssigg response`, response);

                    setGroupsForAssign(response.data);
                },
                error => showAlert(error, 'error'));
    }

    useEffect(() => {
        getAllCamundaUsers();
        getGroupsForAssign();

    }, []);

    if (!shownUsers) {
        return <>...</>
    }

    function Row(props) {
        const {row} = props;
        const classes = useRowStyles();

        const [menuAnchorEl, setMenuAnchorEl] = useState(null);
        const handleCloseMenu = () => {
            setMenuAnchorEl(null);
        };

        const handleOpenMenuClick = (event) => {
            currentUserRef.current = row;
            setMenuAnchorEl(event.currentTarget);
        };

        const dateFormat = 'DD MMM YYYY';
        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    {/*<TableCell>{row.id}</TableCell>*/}
                    <TableCell>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Box m={1}>
                                <Avatar src={row.imageURL && row.imageURL || ''}/>
                            </Box>

                            <Box m={1} mr={5}>
                                <div>
                                    <Typography variant="body1">
                                        {(row.firstName || '')} {(row.lastName || '')}
                                    </Typography>
                                    <Typography color="textSecondary" variant="caption">
                                        {row.email}
                                    </Typography>
                                </div>
                            </Box>
                        </Grid>
                    </TableCell>
                    {/*<TableCell>{row.lastActivity}</TableCell>*/}
                    <TableCell>{row.roles.join(", ")}</TableCell>
                    <TableCell>{row.groups.map(g => g.name).join(", ")}</TableCell>
                    {/*<TableCell>{row.createdAt?.format(dateFormat)}</TableCell>*/}
                    {/*<TableCell>{row.updatedAt?.format(dateFormat)}</TableCell>*/}
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.lastLoginAt?.fromNow()}</TableCell>
                    {/*<TableCell>{row.lastLogoutAt?.fromNow()}</TableCell>*/}
                    <TableCell align={"right"}>
                        <IconButton onClick={handleOpenMenuClick}>
                            <MoreHorizIcon color="primary"/>
                        </IconButton>

                        <Menu
                            id="simple-menu"
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={() => {
                                setCreateDialogUser({...row, groupIds: row.groups.map(g => g.id)});
                                setCreateDialogState(true);
                            }}>Edit User</MenuItem>

                            {
                                row && row.state !== 'SUSPENDED' && <MenuItem onClick={() => {
                                    handleSuspendUser(row.id);
                                }}>Suspend User</MenuItem>
                            }

                            {
                                row && row.state === 'SUSPENDED' && <MenuItem onClick={() => {
                                    handleRestoreUser(row.id);
                                }}>Restore User</MenuItem>
                            }

                           { row.state === 'LOCKED' ? <MenuItem onClick={() => {
                                handleUnlockUser(row.id);
                            }}>Unlock User</MenuItem>:<></> }

                            { ServerConfigState.emailIndependent === true ? <MenuItem onClick={() => {
                                handleResetPassword(row.id);
                            }}>Reset Password</MenuItem>:<></> }



                            <MenuItem onClick={() => {
                                handleDeleteDialogClickOpen();
                            }}>Delete User</MenuItem>
                        </Menu>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (<>
            <Box p={4}>
                <Grid container direction="row" justify="space-between"  alignItems="center">
                    <Grid item>
                        <Box pb={3} pl={2}>
                            <Typography variant="h6">Users</Typography>
                        </Box>
                    </Grid>

                    <Grid item className={classes.buttonContainer}>
                        <Box pb={3}>
                            <Button variant="contained" color="primary" onClick={() => setCreateDialogState(true)}>Add User</Button>
                        </Box>
                    </Grid>
                </Grid>
                <TableContainer className={classes.tableContainer} component={Paper} elevation={0} square={true}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    {/*<TableCell>ID</TableCell>*/}
                                    <TableCell>USER</TableCell>
                                    {/*<TableCell>LAST ACTIVITY</TableCell>*/}
                                    <TableCell>ROLE</TableCell>
                                    <TableCell>GROUP</TableCell>
                                    {/*<TableCell>CREATED</TableCell>*/}
                                    {/*<TableCell>UPDATED</TableCell>*/}
                                    <TableCell>STATE</TableCell>
                                    <TableCell>LAST LOGIN</TableCell>
                                    {/*<TableCell>LAST LOGOUT</TableCell>*/}
                                    <TableCell align={'right'}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                        ? shownUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : shownUsers
                                ).map((row) =>
                                    <Row key={row.id} row={row}/>
                                )}
                            </TableBody>
                        </Table>
                        {/*<TableBody>*/}
                        {/*    {emptyRows > 0 && (*/}
                        {/*        <TableRow style={{height: 53 * emptyRows}}>*/}
                        {/*            <TableCell colSpan={6}/>*/}
                        {/*        </TableRow>*/}
                        {/*    )}*/}
                        {/*</TableBody>*/}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                    colSpan={3}
                                    count={shownUsers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>


            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a user account cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={handleDeleteDialogClose} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                        <Button onClick={deleteUser} variant="contained" color="primary" autoFocus>
                            Delete user
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <CreateUserDialog
                isOpen={createDialogState}
                user={createDialogUser}
                groupsForAssign={groupsForAssign}
                onClose={createDialogClose}
                onSubmit={createOrUpdateUserCompleted}
            />
        </>
    );
}
