import {STORE_SERVER_CONFIGURATION} from "../types";

const handlers = {
    [STORE_SERVER_CONFIGURATION]: (state, {payload}) => ({
        ...payload,
    }),
    DEFAULT: state => state
};

export const serverConfigReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
