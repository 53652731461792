import React, {useReducer} from 'react';
import {ServerConfigContext} from './serverConfigContext';
import {serverConfigReducer} from './serverConfigReducer';
import {STORE_SERVER_CONFIGURATION} from '../types';

export const ServerConfigState = ({children}) => {
    const initialState = {}; //emailIndependent,
    const [state, dispatch] = useReducer(serverConfigReducer, initialState);

    function storeFetchedServerConfig(data) {
        console.log(`storeFetchedServerConfig triggered`, data);

        dispatch({
            type: STORE_SERVER_CONFIGURATION,
            payload: data
        })
    }

    return (
        <ServerConfigContext.Provider value={{
            storeFetchedServerConfig: storeFetchedServerConfig,
            ServerConfigState: state
        }}>
            {children}
        </ServerConfigContext.Provider>
    )
};
