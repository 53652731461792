import {STORE_FETCHED_USERS} from '../types'

const handlers = {
    [STORE_FETCHED_USERS]: (state, {payload}) => ({...state, ...payload}),
    DEFAULT: state => state
};

export const UsersReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
