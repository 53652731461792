import React from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import Card from '@material-ui/core/Card/index';
import CardContent from '@material-ui/core/CardContent/index';
import Typography from '@material-ui/core/Typography/index';
import Paper from "@material-ui/core/Paper/index";
import Grid from "@material-ui/core/Grid/index";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& h3": {
            fontWeight: 100,
        },
        "& p": {
            marginTop: 10,
            fontWeight: 500
        },
    },
}));

export default function DashboardCard({cardData, onCardClick}) {
    const classes = useStyles();

    return (
        <Paper variant="outlined" square={true} className={classes.paper} onClick={() => {
            if (onCardClick) {
                onCardClick(cardData);
            }
        }}>

            <Typography variant="h3" color={onCardClick ? 'primary' : 'textSecondary'}>
                {cardData.data}
            </Typography>

            <Typography variant="body1" color='textSecondary'>
                {cardData.title}
            </Typography>

        </Paper>
    );
}
