import React from 'react';

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {TaskOverview} from "../components/tasksComponents/TaskOverview";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        backgroundColor: "#fff",
        boxShadow: "rgb(230 230 230) 0px 1px 1px 0px",
        marginBottom: '1px'
    },
    taskContainer: {
        paddingBottom: 72,
        '&': {
            position: 'relative'
        },
        '& #ActionContainer': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            margin: 0,
            padding: "16px 32px",
            background: '#ffffff',
            boxShadow: '0 -2px 5px -2px rgba(0, 0, 0, 0.12)',
            zIndex: 5,
            '& div': {
                justifyContent: 'flex-end'
            }
        },
        '& [class*="MuiButton-contained"]:active':{
            boxShadow: 'none'
        },
        '& [class*="MuiButton-contained"]:hover':{
            boxShadow: 'none'
        },
        '& [class*="MuiButton-contained"]':{
            boxShadow: 'none'
        },
        '& #form': {
            overflow: 'auto',
            background: '#ffffff'
        },
        '& #mainFormBox': {
            padding: '32px 32px 80px 32px'
        },
        '& #checks': {
            borderLeft: '1px solid ' + theme.palette.divider
        },
        '& #checklist': {
            maxHeight: '100%',
            overflow: 'auto',
            paddingBottom: 72
        },
        '& #checks p': {
            lineHeight: 2
        },
        '& #mainStepHeader h4': {
            fontSize: '1.5rem'
        },
        '& form h5': {
            fontSize: '1.25rem'
        },
        '& form > div': {
            padding: '0px'
        },
        '& form p': {
            lineHeight: 2,
            wordBreak: 'break-word'
        }
    },
}));

export const Journey = ({processDefinitionKey, processInstanceId,taskId, title, onCloseCBK, instanceEnterCbk,instanceExitCbk, taskEnterCbk}) => {
    const classes = useStyles();
    return (
        <Grid id="service" container direction="row" justify="flex-start" alignItems="stretch" item xs={12} style={{flex: "1 1 auto"}}>
            <Grid container direction="column" item xs={12}>
                <Grid item className={classes.pageHeader} xs={12} style={{flex: "0 1 auto"}}>
                    <Box pl={4} pr={4} pt={1} pb={1}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Typography variant="h6">
                                {title}
                            </Typography>

                            <IconButton aria-label="close" onClick={() => onCloseCBK ? onCloseCBK() : null}>
                                <CloseIcon/>
                            </IconButton>

                        </Grid>
                    </Box>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12}
                      style={{flex: "1 1 auto"}}>
                    <Grid container direction="row" justify="flex-start" alignItems="stretch" className={classes.taskContainer} item xs={12}>
                        <TaskOverview {...{
                            processDefinitionKey,
                            processInstanceId,
                            taskId,
                            instanceExitCbk,
                            instanceEnterCbk,
                            taskEnterCbk,
                        }}/>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
};
