import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import QueryString from "query-string"
import {AlertContext} from "../context/alert/alertContext";
import axios from 'axios';
import moment from 'moment';
import * as _ from 'lodash';
import TaskCard from "../components/tasksComponents/TaskCard";
import {TaskOverview} from "../components/tasksComponents/TaskOverview";
import {contextPath} from "../contextPath";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import FilterSelect from "../components/tasksComponents/FilterSelect";
import {isIeBrowser, tasksUrl, usersUrl} from "../variables";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import IconButton from '@material-ui/core/IconButton';

import Slide from "@material-ui/core/Slide";
import {UsersContext} from "../context/users/usersContext";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

moment().format();

const useStyles = makeStyles((theme) => ({
    taskListSlider : {
        transition: 'none!important'
    },
    taskListContainer: {
        maxHeight: 'calc(100vh - 126px)',
        backgroundColor: 'none',
        overflowY:'auto',
        overflowX: 'none',
    },
    containerHeader: {
        minHeight: '60px',
        padding: '0px 16px',
        boxShadow: '0 1px 1px 0 #e6e6e6'
    },
    taskContainer: {
        paddingBottom: 72,
        '&': {
            position: 'relative',
            background: '#ffffff'
        },
        '& #ActionContainer' : {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            margin: 0,
            padding: 16,
            background: '#ffffff',
            boxShadow: '2px -1px 4px -2px rgb(0 0 0 / 12%)',
            zIndex: 5
        },
        '& #form': {
            backgroundColor: 'white',
            maxHeight: '100%',
            overflow: 'auto',
            paddingTop: 32,
        },
        '& #mainStepHeader': {
            padding: '0 32px'
        },
        '& #stepSubHeader': {
            padding: '0 32px'
        },
        '& #checks': { },
        '& #checklist': {
            maxHeight: '100%',
            overflow: 'auto',
            paddingBottom: 72
        },
        '& #checks p': {
            lineHeight: 2
        },
        '& #mainStepHeader h4': {
            fontSize: '1.5rem'
        },
        '& form h5' : {
            fontSize: '1.25rem'
        },
        '& form p': {
            lineHeight: 2,
            wordBreak: 'break-word'
        }
    },
    arrowCollapseButton: {
        border: '1px solid ' + theme.palette.divider,
        borderRadius: '50%',
        backgroundColor: 'white',
        fontSize: 30,
        color: 'black'
    }
}));

const tasksFilterNamesDictionary = {
    all: 'All',
    pending: 'Pending tasks',
    group: 'Group tasks',
    stale: 'Stale tasks',
    stale_day: 'Stale 1-2 days tasks',
    stale_week: 'Stale 1 week tasks',
    stale_month: 'Stale 1 month tasks',
    stale_more: 'More than 1 month',
};

const tasksCategoryNamesDictionary = {
    'all':"All",
    "review":"Review",
    "journey":"Journey"
}

export const Tasks = (props) => {
    const routerTaskId = props.match.params.task_id || '';
    const routeQueryParsed = QueryString.parse(props.location.search);
    const usedTaskFilter = routeQueryParsed.filter || 'all';

    const [tasks, setTasks] = useState(null);
    const [shownTask, setShownTask] = useState(null);
    const [taskPanelIsVisible, setTaskPanelIsVisibility] = useState(true);
    const [taskReloadTrigger, setTaskReloadTrigger] = useState(true);

    const {showAlert} = useContext(AlertContext);
    const {UsersState, storeFetchedUsers} = useContext(UsersContext);
    const classes = useStyles();
    const history = useHistory();

    const {LoggedUserState} = useContext(LoggedUserContext);
    const isAdmin = LoggedUserState.user.roles && (LoggedUserState.user.roles.indexOf('ADMIN') !== -1);
    const isLeadOrCustomer = LoggedUserState.user.roles && (LoggedUserState.user.roles.indexOf('LEAD') !== -1 || LoggedUserState.user.roles.indexOf('CUSTOMER') !== -1);
    const defaultCategory = isAdmin? "review" : "all";
    const usedTaskCategory = routeQueryParsed.category || defaultCategory;

    console.log(`Tasks Page Route data: routerTaskId: ${routerTaskId}  usedTaskFilter:${usedTaskFilter}  usedTaskCategory:${usedTaskCategory}`);
    console.log(`Tasks Page shownTask id: ${shownTask && shownTask.task.id} `);

    function fetchGroupUsers(groupId) {
        axios({
            method: 'get',
            url: usersUrl,
            params: {
                groupId: groupId,
            }
        })
            .then((response) => {
                    storeFetchedUsers({
                        [groupId]: response.data.map((user) => ({
                            ...user, imageURL: user.imageURL || `${usersUrl}/${user.id}/image`,
                        }))
                    });
                },
                error => showAlert(error, 'error'));
    }

    useEffect(() => {
        console.log(`TaskList:  request ${tasksUrl}`);

        const params = {
            filter: usedTaskFilter,
            category: usedTaskCategory,
        }


        axios({
            method: 'get',
            url: tasksUrl,
            params
        })
            .then((response) => {
                    console.log('TaskList response:', response);

                    // add some calculated properties
                    response.data.forEach(item => {
                        item.task.relativeTime = 'Created ' + moment(item.task.createTime).fromNow();
                        const usersUrl = `${contextPath}api/users`;

                        if (item.assignee) {
                            item.assignee.imageURL = `${usersUrl}/${item.assignee.id}/image`;
                        }
                    });

                    const fetchGroupUsersKeys = {};

                    _.each(response.data, item => {
                        _.each(item.candidateGroups, (assignedGroup) => {
                            if (assignedGroup && !fetchGroupUsersKeys[assignedGroup.id]) {
                                fetchGroupUsersKeys[assignedGroup.id] = assignedGroup;
                            }
                        });
                    });

                    Object.keys(fetchGroupUsersKeys).forEach(groupId => {
                            if (!UsersState[groupId]) {
                                fetchGroupUsers(groupId);
                            }
                        }
                    );

                    setTasks(response.data);

                    if (routerTaskId) {
                        const shownTask = response.data.filter(item => item.task.id === routerTaskId);

                        if (shownTask.length) {
                            setShownTask(shownTask[0]);
                        } else {
                            // for Review Customer tasks, when taskId come from router navigation
                            setShownTask({task: {id: routerTaskId}});
                        }
                    }
                },
                error => showAlert(error, 'error')
            )
    }, [usedTaskFilter, usedTaskCategory, taskReloadTrigger]);

    useEffect(() => {
        if (routerTaskId) {
            console.log(' useEffect [routerTaskId] triggered');
            if (tasks) {
                setShownTask(...tasks.filter(item => item.task.id === routerTaskId));
            }
        }
    }, [routerTaskId]);


    function onTaskClickCallback(clickedTaskItem) {
        console.log('onTaskClickCallback: ', clickedTaskItem, ' click will be ', !(shownTask && shownTask.task.id === clickedTaskItem.task.id) ? 'handled' : ' ignored');

        if (!(shownTask && shownTask.task.id === clickedTaskItem.task.id)) {
            history.push(`/tasks/${clickedTaskItem.task.id}${props.location.search}`);
        }
    }

    function onTaskFilterSelectCallback(filterName) {
        console.log('onTaskFilterSelectCallback: ', filterName);

        if (filterName) {
            setShownTask(null);
            history.push(`/tasks?${QueryString.stringify({
                ...routeQueryParsed,
                filter: filterName, 
                ...(usedTaskCategory ? {category: usedTaskCategory} : {})
            })}`);
        }
    }

    function onTaskCategorySelectCallback(categoryName) {
        console.log('onTaskCategorySelectCallback: ', categoryName);

        if (categoryName) {
            setShownTask(null);
            history.push(`/tasks?${QueryString.stringify({...routeQueryParsed, filter: usedTaskFilter, category: categoryName})}`);
        }
    }

    function onTaskExitCbk() {
        showAlert('Success!', 'success');
        setShownTask(null);
        history.push(`/tasks`);
        setTaskReloadTrigger(prevState => !prevState);
    }

    function onTaskAssignCbk() {
        showAlert('Success!', 'success');
        setShownTask(null);
        setTaskReloadTrigger(prevState => !prevState);
    }

    function toggleTasksSidePanel(event) {
        console.log(`toggleTasksSidePanel clicked!`);
        event.stopPropagation();
        setTaskPanelIsVisibility(prevValue => !prevValue);
    }

    const filter = <Grid item>
        <FilterSelect
            valueNamesDictiony={tasksFilterNamesDictionary}
            defaultValue={usedTaskFilter}
            selectCallback={onTaskFilterSelectCallback}
        />
    </Grid>

    const categoryFilter = <></>/*
    Removed - default filter rules shall apply to the admin as well
    (isAdmin && <Grid item>
        <FilterSelect
            valueNamesDictiony={tasksCategoryNamesDictionary}
            defaultValue={usedTaskCategory}
            selectCallback={onTaskCategorySelectCallback}
        />
    </Grid>) || */

    return (
        <Grid id="tasks" container direction="row" justify="flex-start" alignItems="stretch" item xs style={{flex: "1 1 auto"}}>

            <Slide className={classes.taskListSlider} direction="right" in={taskPanelIsVisible} mountOnEnter unmountOnExit>
                <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={3} wrap="nowrap">
                    <Grid container direction="row" justify="flex-end" alignItems="center"
                          className={classes.containerHeader}>
                        {filter}
                        {categoryFilter}
                    </Grid>
                    { tasks && tasks.length > 0 ? <Grid className={classes.taskListContainer} item xs style={{flex: "1 1 auto"}}>
                            { tasks.map((item, index) => (<TaskCard key={item.task.id}
                                                                  taskItem={item}
                                                                  isActive={shownTask && shownTask.task.id === item.task.id}
                                                                  {...{onTaskAssignCbk}}
                                                                  onTaskClick={onTaskClickCallback}/>)) }
                    </Grid> :  <Grid className={classes.taskListContainer} item xs style={{flex: "1 1 auto"}}>
                        { tasks != null ? <Box m={6}><Typography variant={"subtitle1"} style={{ fontWeight: '500', textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)' }}>
                            No tasks for today. <br/> Enjoy the day!</Typography></Box> : '' } </Grid> }
                </Grid>
            </Slide>

            <Grid container direction="column" justify="flex-start" alignItems="stretch"
                  className={classes.taskContainer} item xs>
                <Grid style={{ position: 'relative', zIndex: 5 }}>
                    <Grid style={{position: 'absolute', left: 0, top: 36, background: '#F6F9FC', borderRadius: '0 50% 50% 0', boxShadow: 'rgb(0 0 0 / 7%) 2px 0px 4px 0px'}}>
                        {taskPanelIsVisible ? <IconButton aria-label="delete" onClick={toggleTasksSidePanel} color="primary">
                                <ArrowBackIosIcon/>
                            </IconButton> :
                            <IconButton aria-label="delete" onClick={toggleTasksSidePanel} color="primary">
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        }
                    </Grid>
                </Grid>

                <TaskOverview {...{shownTask, onTaskExitCbk, noStep: true, showSections: true}}/>
            </Grid>
        </Grid>
    );
};
