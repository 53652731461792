import React, {useContext, useState, useEffect} from 'react';
import {LoggedUserContext} from "../../context/loggedUser/loggedUserContext";
import {contextPath} from "../../contextPath";
import {FormContainer} from "sancus-html-client/dist/FormContainer";

import Grid from "@material-ui/core/Grid";
import {AlertContext} from "../../context/alert/alertContext";
import {getStore} from "sancus-client-common/dist/store";


export const TaskOverview = ({shownTask, taskEnterCbk, onTaskExitCbk, instanceEnterCbk, instanceExitCbk, setShownTask, processDefinitionKey, taskId, processInstanceId, noStep, showSections}) => {
    const {LoggedUserState, setUserLoggedIn, setUserLoggedOut} = useContext(LoggedUserContext);
    const {showAlert} = useContext(AlertContext);
    //TODO retrieve from taskOverview init
    // update formContainer interfaces
    //
    const shownTaskId = shownTask?.task.id;

    const setInProgress = (value) => {
        return {
            type: 'SET_IN_PROGRESS',
            payload: value,
        };
    };

    useEffect(() => {
        const store = getStore();

        if (shownTaskId || processDefinitionKey) {
             store.dispatch(setInProgress(true));
        }
    }, [shownTaskId,processDefinitionKey]);

    if (!shownTaskId && !processDefinitionKey) {
        return <Grid item xs></Grid>
    }

    return (
        <>
            {/*<ThemeProvider theme={theme}>*/}

            <FormContainer key={taskId || shownTaskId || processDefinitionKey || processInstanceId}
                           processInstanceId={processInstanceId}
                           processDefinitionKey={processDefinitionKey}
                           taskId={shownTaskId}
                           showSections={showSections}
                           layout={"facing"}
                           callbacks={{
                               taskEnter(taskId) {
                                   console.log('_GUI callback triggered_ taskEnter', taskId);
                                   taskEnterCbk && taskEnterCbk(taskId);
                               },
                               instanceEnter(instanceId) {
                                   console.log('_GUI callback triggered_ instanceEnter', instanceId);
                                   instanceEnterCbk && instanceEnterCbk(instanceId);
                               },
                               taskExit(taskId) {
                                   console.log('_GUI callback triggered_ taskExit from GUI', taskId);
                                   onTaskExitCbk && onTaskExitCbk(taskId);
                               },
                               instanceExit(instanceStatus) {
                                   console.log('_GUI callback triggered_ instanceExit from GUI', instanceStatus);
                                   instanceExitCbk && instanceExitCbk(instanceStatus);
                               },
                               error(error){
                                    if (error?.status === 401){
                                        showAlert('User is not logged in or session has expired').then(()=>{
                                            setUserLoggedOut({user: {}});
                                        })
                                        return true;
                                    }
                               }
                           }} noStep={noStep} contextPath={contextPath} loggedUserState={LoggedUserState}/>

            {/*</ThemeProvider>*/}
        </>
    )
};
