import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import {makeStyles, useTheme} from "@material-ui/core/styles";
import {AlertContext} from "../context/alert/alertContext";
import {contextPath} from "../contextPath";
import Grid from "@material-ui/core/Grid";
import DashboardCard from "../components/dashboradComponents/DashboardCard";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Doughnut from "../components/dashboradComponents/Doughnut";
import LineChart from "../components/dashboradComponents/LineChart";
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";
import DoughnutSimple from "../components/dashboradComponents/DoughnutSimple";
import UserManagement from "./UserManagement";

import "chartist/dist/chartist.min.css"

moment().format();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 35,
    },
    paper: {
        color: theme.palette.text.secondary,
    },
    chartTitle: {
        fontWeight: 100,
        padding: 16,
    },
    welcomeContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));


const dashboardCardsDictionary = {
    "pending": {
        title: "Pending Tasks",
        fetchLink: "/user_pending",
    },
    "completedToday": {
        title: "Completed Today",
        fetchLink: "/user_completed",
    },
    "group": {
        title: "Group Tasks",
        fetchLink: "/group_pending",
    },
    "stale": {
        title: "Stale Tasks",
        fetchLink: "/stale",
    },
    "workflowActivity": {
        title: "",
        fetchLink: "/applications",
    },
};

const dashboardUrl = `${contextPath}api/dashboard`;

function prepareDataForChart(receivedData = {}, chartName, colorsWithShades, additionalOptions) {

    if (chartName === 'stale') {
        return {
            data: [
                {
                    label: 'More than 1 month',
                    value: receivedData.older,
                    filterName: 'stale_more',
                },
                {
                    label: '1 week',
                    value: receivedData.week,
                    filterName: 'stale_week',
                },
                {
                    label: '1 month',
                    value: receivedData.month,
                    filterName: 'stale_month',
                },
                {
                    label: '1-2 days',
                    value: receivedData.days,
                    filterName: 'stale_day',
                },
            ],
            colors: ['#FF183C', '#FF8400', '#ffcc00', '#99cc33']
        };
    }

    // for tests
    let _receivedData_hardcoded = [{
        "cnt": 13,
        "label": "Retail Onboarding",
        "tasks": [{
            "cnt": 5, "label": "Activity", "tasks": null
        }, {
            "cnt": 4,
            "label": "Put OTP",
            "tasks": null
        }, {
            "cnt": 3, "label": "Contact Step", "tasks": null
        }, {
            "cnt": 1, "label": "OnFido Authorization", "tasks": null
        }]
    }, {
        "cnt": 20,
        "label": "PEP hits review",
        "tasks": [{
            "cnt": 15, "label": "Review", "tasks": null
        }, {
            "cnt": 5, "label": "Approve hit", "tasks": null
        }]
    }];

    if (chartName === 'workflowActivity') {
        let dataSource = receivedData || [];
        const mixedColorsWithSades = colorsWithShades //_.shuffle(colorsWithShades);
        const mixedColorsWithSadesLength = mixedColorsWithSades.length;

        const config = {
            labels: [],
            filterNames: [],
            datasets: [{
                data: [],
                backgroundColor: [],
                datalabels: {
                    anchor: 'center',
                    backgroundColor: null,
                    borderWidth: 0
                }
            }, {
                data: [],
                backgroundColor: [],
                datalabels: {
                    anchor: 'center',
                    backgroundColor: null,
                    borderWidth: 0
                }
            }],
        };



        (dataSource).forEach((areaData, processIndex) => {
            config.datasets[0].data.push(0);
            if (mixedColorsWithSadesLength >0) {
                config.datasets[0].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].main);
            }
            config.labels.push(areaData.label);
            config.datasets[1].data.push(areaData.cnt);
            if (mixedColorsWithSadesLength >0) {
                config.datasets[1].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].main);
            }
        });

        (dataSource).forEach((areaData, processIndex) => {
            const dataSource = areaData.tasks || [];

            (dataSource).forEach((areaData, taskIndex) => {
                config.labels.push(areaData.label);
                config.datasets[0].data.push(areaData.cnt);

                // if we have more tasks than shades
                if (mixedColorsWithSadesLength >0) {
                    const shadesColorsLength = mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].shades.length;
                    config.datasets[0].backgroundColor.push(mixedColorsWithSades[processIndex % mixedColorsWithSadesLength].shades[taskIndex % shadesColorsLength]);
                }
            });
        });

        return config;
    }

    if (chartName === 'completedToday') {
        const data = [];
        const labels = [];

        Object.keys(receivedData.month || []).forEach((dateString) => {
            data.push(receivedData.month[dateString]);
            labels.push(moment(dateString).format('MMM D'));
        });

        return {
            data,
            labels
        };
    }
}

function fetchDashboardData({filterName, additionalParams}) {
    const fetchLink = dashboardCardsDictionary[filterName].fetchLink;
    let params = additionalParams;

    return axios({
        method: 'get',
        url: `${dashboardUrl}${fetchLink}`,
        ...(params ? {params: params} : {}),
    })
        .then((response) => response.data)
}

export const Dashboard = (props) => {
    const {showAlert} = useContext(AlertContext);
    const {setUserLoggedOut, LoggedUserState} = useContext(LoggedUserContext);
    const [dashboardData, setDashboardData] = useState({});
    const tableName = props.match.params.tableName || '';
    const history = useHistory();
    const theme = useTheme();
    const showUserManagement = tableName === 'user_management' && LoggedUserState.user.roles.indexOf('ADMIN') > -1;


    console.log('tableName: ', tableName);

    console.log('dashboardDataState: ', dashboardData);

    const colorsWithShades = theme?.custom?.dashboard?? [];

    useEffect(() => {
            if (showUserManagement) {
                return;
            }

            Object.keys(dashboardCardsDictionary).forEach((filterName) => {
                fetchDashboardData({filterName}).then(
                    (data) => {
                        console.log(`${filterName} data:`, data);
                        setDashboardData(
                            (previousState) => {
                                return ({
                                    ...previousState,
                                    [filterName]: {
                                        data: data
                                    }
                                })
                            })
                    },
                    error => showAlert(error, 'error'))
            });
        }, []
    );

    function pieOnClickCallback(pieData) {
        console.log('pieOnClickCallback run with options: ', pieData);

        if (pieData.filterName) {
            history.push(`/tasks?filter=${pieData.filterName}`);
        }
    }

    function onCardClickCallback(cardData) {
        console.log('onCardClickCallback run with options: ', cardData);

        if (cardData.filterName) {
            history.push(`/tasks?filter=${cardData.filterName}`);
        }
    }

    const classes = useStyles();

    if (showUserManagement) {
        return <UserManagement/>;
    }

    return (
        <div id="dashboard" className={classes.root}>
            <Grid container spacing={5}>
                <Grid item xs={12}>

                    <Grid container className={classes.welcomeContainer}
                          direction="row"
                          justify="center"
                          alignItems="center">

                        <Typography color="textSecondary" variant="h6" className={classes.chartTitle}>
                            Welcome back, {LoggedUserState.user.firstName || LoggedUserState.user.position || 'officer'}
                        </Typography>

                        <Typography color="textSecondary" variant="body2" className={classes.chartTitle} align="right">
                            {moment().format('dddd, D MMM  YYYY')}
                        </Typography>
                    </Grid>

                    <Divider variant="fullWidth"/>

                </Grid>

                <Grid item xs={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['pending'] ? (<DashboardCard
                        cardData={{
                            filterName: 'pending',
                            data: dashboardData['pending'].data,
                            ...dashboardCardsDictionary['pending']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>

                <Grid item xs={3}>
                    {dashboardData['completedToday'] ? (<DashboardCard
                        cardData={{
                            filterName: 'completedToday',
                            data: ('today' in dashboardData['completedToday'].data) ? dashboardData['completedToday'].data.today : undefined,
                            ...dashboardCardsDictionary['completedToday']
                        }}
                        onCardClick={null}
                    />) : ''}
                </Grid>


                <Grid item xs={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['group'] ? (<DashboardCard
                        cardData={{
                            filterName: 'group',
                            data: ('total' in dashboardData['group'].data) ? dashboardData['group'].data.total : undefined,
                            ...dashboardCardsDictionary['group']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>

                <Grid item xs={3} style={{ cursor: 'pointer' }}>
                    {dashboardData['stale'] ? (<DashboardCard
                        cardData={{
                            filterName: 'stale',
                            data: ('total' in dashboardData['stale'].data) ? dashboardData['stale'].data.total : undefined,
                            ...dashboardCardsDictionary['stale']
                        }}
                        onCardClick={onCardClickCallback}
                    />) : ''}
                </Grid>


                <Grid item xs={12} sm={6}>
                    {dashboardData['completedToday'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Completed Task Activity
                        </Typography>
                        <Divider variant="fullWidth"/>
                        <LineChart
                            config={prepareDataForChart(dashboardData['completedToday'].data, 'completedToday', colorsWithShades)}
                        />
                    </Paper>) : ''}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {dashboardData['stale'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Stale Tasks
                        </Typography>

                        <Divider variant="fullWidth"/>

                        <Doughnut
                            config={prepareDataForChart(dashboardData['stale'].data, 'stale', colorsWithShades)}
                            onClickCallback={pieOnClickCallback}
                        />
                    </Paper>) : ''}
                </Grid>

                <Grid item xs={12}>
                    {dashboardData['workflowActivity'] ? (<Paper variant="outlined" square={true} className={classes.paper}>
                        <Typography color="textSecondary" variant="body1" className={classes.chartTitle}>
                            Overall Workflow Activity
                        </Typography>

                        <Divider variant="fullWidth"/>

                        <DoughnutSimple
                            config={prepareDataForChart(dashboardData['workflowActivity'].data, 'workflowActivity', colorsWithShades)}
                        />
                    </Paper>) : ''}
                </Grid>
            </Grid>
        </div>
    )
};
