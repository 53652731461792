/* eslint-disable import/first */
// __webpack_nonce__ = "1234567890" // eslint-disable-line no-undef

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ServerConfigState} from "./context/serverConfig/serverConfigState";
import {LoggedUserState} from "./context/loggedUser/LoggedUserState";
import {UsersState} from "./context/users/usersState";
import {AlertState} from "./context/alert/AlertState";
import axios from 'axios';
import {createGenerateClassName, jssPreset, StylesProvider, ThemeProvider} from '@material-ui/core/styles';
import themePromiseFN from './theme';
import {GroupsState} from "./context/groups/GroupsState";
import {create} from "jss";
import URLParams from "sancus-client-common/dist/utils/utils";
import CssBaseline from '@material-ui/core/CssBaseline';
import Chart from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels";
axios.defaults.withCredentials = true;

Chart.defaults.global.defaultFontFamily = "Roboto, sans-serif";
Chart.plugins.unregister(ChartDataLabels);


const generateClassName = createGenerateClassName({
    seed: "officerApp"
});

const createGenerateId = () => {
    let counter = 0;
    let timestamp = new Date().getTime();
    return (rule, sheet) => {
        return `sancus--${rule.key}-${timestamp}-${counter++}`
    }
};

const jss = create({
    plugins: [...jssPreset().plugins],
    createGenerateId
});

const urlParams = URLParams();
const themeName = urlParams.theme ?? 'default';


async function go(){
    const theme = await themePromiseFN(themeName);
    return new Promise(resolve => {
        ReactDOM.render(
            (
                <ServerConfigState>
                    <LoggedUserState>
                        <UsersState>
                            <GroupsState>
                                <AlertState>
                                    <ThemeProvider theme={theme}>
                                        <StylesProvider jss={jss} generateClassName={generateClassName}>
                                            <CssBaseline/>
                                            <App/>
                                        </StylesProvider>
                                    </ThemeProvider>
                                </AlertState>
                            </GroupsState>
                        </UsersState>
                    </LoggedUserState>
                </ServerConfigState>
            ), document.getElementById('root'),()=>resolve()
        );
    })
}

go().then(()=>console.log("Review App Rendered"))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
