import React, { useState, useEffect, useContext } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {blackButtonStyles, usersUrl} from "../variables";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {TablePaginationActions} from "./UserManagement";
import workflowManagementServiceFactory from "../services/workflowManagement.service";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField/TextField";
import {AlertContext} from "../context/alert/alertContext";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {DropzoneDialog} from 'material-ui-dropzone'

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },

    }
});

const workflowManagementService = workflowManagementServiceFactory();

const CreateWorkflowDialog = ({openCreateDialog, setOpenCreateDialog, onCreateWorkflow, fetchAllWorkflows}) => {
    const { showAlert } = useContext(AlertContext);
    useEffect(() => {
        //clear if dialog is not visible.
        if(!openCreateDialog) {

        }
    }, [openCreateDialog]);

    return  <DropzoneDialog
        open={openCreateDialog}
        onSave={async files=>{
            onCreateWorkflow(files)
                .then(
                    () => showAlert("Upload successful", "success"),
                    err => {
                        console.error(err);
                        showAlert(`Unexpected error`, 'error')
                    }
                );

            setTimeout(() => {
                fetchAllWorkflows();
            }, 1000);

            setOpenCreateDialog(false);
        }}
        acceptedFiles={['.bpmn','.dmn']}
        showPreviews={true}
        showFileNames={true}
        filesLimit={10}
        showFileNamesInPreview={true}
        showAlerts={false}
        useChipsForPreview={true}
        // maxFileSize={5000000}
        onClose={()=>setOpenCreateDialog(false)}
    />
};

const EditWorkflowDialog = ({workflow, openDialog, closeDialog, onEditWorkflow}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ name, setName ] = useState(workflow.name);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await workflowManagementService.editWorkflow({
                id: workflow.id,
                name,
            });
            if(result) {
                onEditWorkflow(result);
                //closeDialog();
                showAlert(`workflow ${result.name} edited successfully`, 'success');
            }
            else {
                showAlert(`Could not edit`, 'error');
            }
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error`, 'error');
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Edit workflow ${workflow.name}`}</DialogTitle>
            <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <TextField fullWidth variant="outlined"
                                       margin={"normal"}
                                       label={"Workflow id"}
                                       required={true}
                                       value={workflow.id}
                                       disabled={true}
                            />
                            <TextField fullWidth variant="outlined" margin={"normal"}
                                       label={"Workflow name"}
                                       required={true}
                                       value={name}
                                       onChange={(evt) => {
                                           setName(evt.target.value);
                                       }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={() => {
                            closeDialog();
                        }} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                        <Button variant="contained" color="primary" autoFocus type="submit">
                            Edit Workflow
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const DeleteWorkflowDialog = ({workflow, openDialog, closeDialog, onDeleteWorkflow}) => {
    const classes = useStyles();
    const { showAlert } = useContext(AlertContext);

    const [ cascadeDel, setCascadeDel ] = useState(false);


    const handleSubmit = async () => {
        try {
            await workflowManagementService.deleteWorkflow({
                key: workflow.key,
                cascade: cascadeDel,
            });

            // if we here then we got 200
            onDeleteWorkflow(workflow.id);
            setCascadeDel(false);
            showAlert(`workflow ${workflow.key} deleted successfully`, 'success');
        }
        catch (e) {
            console.error(e);

            if (e.response && e.response.status === 409) {
                showAlert(e, 'error');
            } else {
                showAlert(`Unexpected error`, 'error');
            }
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                closeDialog();
            }}
        >
            <DialogTitle>{`Delete workflow ${workflow.key}`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deleting a workflow cannot be undone!
                </DialogContentText>


                <Grid container direction={'row'}>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox value={cascadeDel}
                                          color="primary"
                                          onChange={(evt) => {
                                              setCascadeDel(evt.target.checked);
                                          }}
                                />
                            }
                            label="Cascade"
                        />

                    </Grid>


                </Grid>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Box pr={1}>
                <Button onClick={() => {
                    closeDialog();
                }} color="primary">
                    Cancel
                </Button>
                </Box>
                <Box p={2}>
                <Button variant="contained" color="primary" autoFocus onClick={handleSubmit}>
                    Delete Workflow
                </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const WorkflowManagement = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { showAlert } = useContext(AlertContext);

    const pageSizeOptions = [5, 10, 25, {label: 'All', value: -1}];
    const [ pageSize, setPageSize ] = useState(pageSizeOptions[0]);
    const [ page, setPage ] = useState(0);
    const [ itemsCount, setItemsCount ] = useState(0);
    const [ workflows, setWorkflows ] = useState([]);

    const fetchAllWorkflows = async () => {
        try {
            const result = await workflowManagementService.fetchWorkflows();
            setWorkflows(result);
        }
        catch (e) {
            console.error(e);
            showAlert(`Unexpected error: could not fetch workflows`, 'error');
        }
    };

    useEffect(() => {
        fetchAllWorkflows();
    }, []);

    useEffect(() => {
        //calculate pagination
        if(workflows.length > 0) {
            setItemsCount(workflows.length);
        }
    }, [workflows, pageSize]);

    const handleChangePage = (evt, page) => {
        console.debug(`set page: ${page}`);
        setPage(page);
    };

    const handlePageSizeChange = (evt) => {
        console.debug(`set page size parsed value: ${evt.target.value}`);
        const numValue = isNaN(parseInt(evt.target.value)) ? pageSizeOptions[0] : parseInt(evt.target.value);
        console.debug(`set page size: ${numValue}`);
        setPageSize(numValue);
    };

    const pageFilter = (workflow, index) => {
        return pageSize < 0
            ? true
            : index < pageSize * (page + 1) && index >= page * pageSize
                ? true
                : false;
    }

    const [ openCreateDialog, setOpenCreateDialog ] = useState(false);

    const onCreateWorkflow = async (files) => {
        const deployment = await workflowManagementService.upload(files);
    };

    const onEditWorkflow = (workflow) => {
        console.debug(`edit workflow:`);
        console.debug(workflow);
        handleCloseEditDialog();
        setTimeout(() => {
            setWorkflows(workflows.map(g => g.id === workflow.id ? ({...workflow}) : g));
        });
    };

    const onDeleteWorkflow = (workflowId) => {
        console.debug(`delete workflow: ${workflowId}`);
        handleCloseDeleteDialog();
        setTimeout(() => {
            setWorkflows(workflows.filter(g => g.id !== workflowId));
        });
        //TODO handle set page if all page items deleted!
    }

    const [ openEditDialog, setOpenEditDialog ] = useState(false);
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ editOrDeleteItem, setEditOrDeleteItem ] = useState(null);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        handleCloseEditMenu();
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        handleCloseEditMenu();
    }

    const handleCloseEditMenu = () => {
        setAnchorEl(null);
        setEditOrDeleteItem(null);
        console.debug('clear edit/delete item');
    };

    const handleOpenEditMenuFactory = (workflow) => {
        return (evt) => {
            console.debug('edit delete item:')
            console.debug(workflow);
            setEditOrDeleteItem(workflow);
            setAnchorEl(evt.currentTarget);
        };
    };

    return (<>
        <Box p={4}>
            <Grid container direction="row" justify="space-between"  alignItems="center">
                <Grid item>
                    <Box pb={3} pl={2}>
                        <Typography variant={'h6'}>Workflows</Typography>
                    </Box>
                </Grid>

                <Grid className={classes.buttonContainer}>
                    <Box pb={3}>
                        <Button variant="contained" color="primary" onClick={() => {
                            setOpenCreateDialog(true);
                        }}>Add workflow</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer component={Paper} elevation={0} square={true} style={{ borderBottom: '0px'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell align={'right'}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                workflows.filter(pageFilter).map((workflow, index) => {
                                    return (
                                        <TableRow key={workflow.key} className={classes.root}>
                                            <TableCell>
                                                <Typography variant={'body1'}>{workflow.key}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>
                                                    <span dangerouslySetInnerHTML={{__html:workflow.name}}/>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'body1'}>{workflow.version}</Typography>
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <IconButton onClick={handleOpenEditMenuFactory(workflow)}>
                                                    <MoreHorizIcon color="primary"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={pageSizeOptions}
                                    colSpan={4}
                                    count={itemsCount}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handlePageSizeChange}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>

            <CreateWorkflowDialog openCreateDialog={openCreateDialog} setOpenCreateDialog={setOpenCreateDialog} onCreateWorkflow={onCreateWorkflow} fetchAllWorkflows={fetchAllWorkflows}/>
            {
                editOrDeleteItem && <EditWorkflowDialog workflow={editOrDeleteItem} openDialog={openEditDialog && !!editOrDeleteItem} closeDialog={handleCloseEditDialog} onEditWorkflow={onEditWorkflow}/>
            }
            {
                editOrDeleteItem && <DeleteWorkflowDialog workflow={editOrDeleteItem} openDialog={openDeleteDialog && !!editOrDeleteItem} closeDialog={handleCloseDeleteDialog} onDeleteWorkflow={onDeleteWorkflow}/>
            }
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseEditMenu}
            >
                <MenuItem onClick={async () => {
                    await workflowManagementService.downloadWorkflow({key:editOrDeleteItem.key})
                    handleCloseEditMenu();
                }}>Download</MenuItem>
               {/* <MenuItem onClick={() => {
                    setOpenEditDialog(true);
                }}>Edit</MenuItem>*/}
                <MenuItem onClick={() => {
                    setOpenDeleteDialog(true);
                }}>Delete</MenuItem>
            </Menu>
        </Box>
        </>
    );
};

export default WorkflowManagement;
