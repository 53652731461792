import 'proxy-polyfill' //Required for react-forms-hook to prevent (near) infinite loop in IE11

import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {BrowserRouter, Route, Switch, Redirect, useLocation} from 'react-router-dom';
import {Dashboard} from './pages/Dashboard';
import {Tasks} from './pages/Tasks';
import {RunJourneys} from "./pages/RunJourneys";
import SignIn from './pages/SignIn';
import {LoggedUserContext} from "./context/loggedUser/loggedUserContext";
import CustomizedAlert from "./components/CustomizedAlert";
import GlobalLoader from "./components/GlobalLoader";
import {contextPath, contextBaseUri,publicURL} from "./contextPath";
import {makeStyles} from "@material-ui/core/styles";
import {Header} from "./components/Header";

import {AlertContext} from "./context/alert/alertContext";
import {UsersContext} from "./context/users/usersContext";
import {groupsUrl, serverConfigUrl, usersUrl} from "./variables";
import {GroupsContext} from "./context/groups/GroupsContext";
import {ServerConfigContext} from "./context/serverConfig/serverConfigContext";
import Grid from "@material-ui/core/Grid";
import ForgotPassword from "./pages/forgotPassword";
import { ForcePasswordPolicyReset } from "./pages/forcePasswordPolicyReset.component";
import ForgotPasswordEnterOtp from "./pages/forgotPasswordEnterOtp";
import ResetPassword from "./pages/resetPassword";
import {ProvideForgotPasswordService} from "./context/users/forgotPassword.service";
import {getStore} from "sancus-client-common/dist/store";
import {PrivateRoute} from "./components/privateRoute";
import GroupManagement from "./pages/GroupManagement.component";
import WorkflowManagement from "./pages/WorkflowManagement.component";
import UserManagement from "./pages/UserManagement";
import { PasswordPolicy } from "./pages/passwordPolicy.component";


const useStyles = makeStyles((theme) => ({
    screen: {
        minHeight: '100vh',
    },
    layoutContainer: {
        backgroundColor: theme.palette.secondary.main,
        overflow: "auto"
    }
}));

function StoreLocation() {
    let location = useLocation();

    useEffect(() => {
        if ('/' !== location.pathname && (location.pathname.includes("/dashboard") || location.pathname.includes("/tasks") || location.pathname.includes("/run_journeys"))) {
            console.log('useEffect last known location for storing', location.pathname);
            sessionStorage.setItem('lats_location',  location.pathname);
        }
    }, [location]);
    return <></>
}

function App() {

    console.log("PUBLIC_URL",publicURL)

    const loginUrl = `${contextPath}api/users/login`;
    const {LoggedUserState, setUserLoggedIn, setUserLoggedOut} = useContext(LoggedUserContext);
    const {storeFetchedUsers} = useContext(UsersContext);
    const {storeFetchedGroups} = useContext(GroupsContext);
    const {showAlert} = useContext(AlertContext);
    const {ServerConfigState, storeFetchedServerConfig} = useContext(ServerConfigContext);
    const [isLoading, setLoading] = useState(false);
    const classes = useStyles();

    // here is a code for support/show GlobalLoader on each axios call
    useEffect(() => {
        // declare a request interceptor for better loading indication
        axios.interceptors.request.use(config => {
            // perform a task before the request is sent
            setLoading(true);
            return config;
        }, error => {
            // handle the error
            setLoading(false);
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // do something with the response data
            setLoading(false);
            return response;
        }, error => {
            // handle the response error
            setLoading(false);
            return Promise.reject(error);
        });
    }, []);

    // check if user logged in and get user info
    // also if user logged In we fetch all Camunda users
    useEffect(() => {
        console.log(`SignIn:  check loggedIn request request ${loginUrl}`);

        function getAllCamundaUsers() {
            axios({
                method: 'get',
                url: usersUrl,
            })
                .then((response) => {
                        console.log('App.js: getAllCamundaUsers response:', response);
                        //, add imageURL link
                        storeFetchedUsers({
                            all: response.data.map((user) => ({
                                ...user, imageURL: user.imageURL || `${usersUrl}/${user.id}/image`,
                            }))
                        });
                    },
                    error => showAlert(error, 'error'));
        }

        function getAllCamundaGroups() {
            axios({
                method: 'get',
                url: groupsUrl,
            })
                .then((response) => {
                        console.log('App.js: getAllCamundaGroups response:', response);
                        //, add imageURL link
                        storeFetchedGroups(response.data);
                    },
                    error => showAlert(error, 'error'));
        }

        axios({
            method: 'get',
            url: serverConfigUrl,
        })
            .then((response) => {
                    console.log('App.js: getServerConfig response:', response);
                    storeFetchedServerConfig(response.data);
                },
                error => showAlert(error, 'error'));


        axios({
            method: 'get',
            url: loginUrl,
        })
            .then((response) => {
                    const {data} = response;
                    console.log('SignIn: response:', response);

                    //TODO FIX IT After backend fix
                    data.imageURL = data.imageURL || `${usersUrl}/${data.id}/image`;
                    setUserLoggedIn({user: data});

                    getAllCamundaUsers();
                    getAllCamundaGroups();
                },
                error => {
                    if ((error.status && error.status !== 401) || (error.response && error.response.status !== 401)) {
                        showAlert(error, 'error');
                    } else {
                        setUserLoggedOut({user: {}});
                        console.log(`SignIn: user doesn't loggedIn or expired`);
                    }
                }
            )
    }, []);

    useEffect(() => {
        const onReduxStoreDataChange = () => {
            const inProgress = getStore().getState().inProgress;
            setLoading(inProgress);
        };

        // Redux store
        const store = getStore();
        const unsubscribeStore = store.subscribe(onReduxStoreDataChange);

        // code here work like in componentWillUnmount()
        return () => unsubscribeStore();
    }, []);

    console.log('App.js: LoggedUserState: ', LoggedUserState);

    let view;
    if (LoggedUserState.isLoggedIn) {
        view = <BrowserRouter basename={publicURL}>
            <StoreLocation/>
            <Grid className={classes.screen} item xs container direction="row" justify="flex-start" alignItems="stretch">
            <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{  minHeight: '100vh'}}>
                <Header/>
                <Grid container direction="column" justify="flex-start" alignItems="stretch" id={"routerContainer"}
                      className={classes.layoutContainer} item xs>
                    <ProvideForgotPasswordService>
                    <Switch>
                        <Route exact path='/'>
                            <Redirect to='/dashboard'/>
                        </Route>

                        <PrivateRoute exact path={'/dashboard/user_management'}>
                            <UserManagement/>
                        </PrivateRoute>

                        <PrivateRoute exact path={'/dashboard/password_policy'}>
                            <PasswordPolicy/>
                        </PrivateRoute>

                        <PrivateRoute exact path={'/dashboard/group_management'}>
                            <GroupManagement/>
                        </PrivateRoute>

                        <PrivateRoute exact path={'/dashboard/workflow_management'}>
                            <WorkflowManagement/>
                        </PrivateRoute>

                        <PrivateRoute exact path='/dashboard/forcePasswordPolicyReset'>
                            <ForcePasswordPolicyReset/>
                        </PrivateRoute>

                        <Route path={'/dashboard/:tableName?'} exact component={Dashboard}/>
                        <Route path={'/tasks/:task_id?'} component={Tasks}/>
                        <Route path={'/run_journeys/:journeyName?/:processInstanceId?'} component={RunJourneys}/>
                        {/*redirect any not know route to dashboard*/}
                        <Route>
                            <Redirect to='/dashboard'/>
                        </Route>
                    </Switch>
                    </ProvideForgotPasswordService>
                </Grid>
            </Grid>
            </Grid>
        </BrowserRouter>
    } else if (LoggedUserState.isLoggedIn === false) {
        view = <BrowserRouter basename={contextBaseUri}>
            <StoreLocation/>
            <ProvideForgotPasswordService>
                    {ServerConfigState.emailIndependent === false &&
                        <Switch>
                            <Route exact path='/'>
                                <SignIn/>
                            </Route>
                            <Route exact path='/forgotPassword'>
                                <ForgotPassword/>
                            </Route>

                            <Route exact path='/forcePasswordPolicyReset'>
                                <ForcePasswordPolicyReset/>
                            </Route>

                            <Route exact path='/activateAccount'>
                                <ForgotPassword flow="activateAccount"/>
                            </Route>

                            <Route exact path='/forgotPasswordEnterOtp'>
                                <ForgotPasswordEnterOtp/>
                            </Route>

                            <Route exact path='/resetPassword'>
                                <ResetPassword/>
                            </Route>
                            <Route>
                                <Redirect to={'/'}/>
                            </Route>
                        </Switch>
                    }

                {ServerConfigState.emailIndependent === true &&
                    <Switch>
                        <Route exact path='/'>
                            <SignIn/>
                        </Route>
                        <Route exact path='/forcePasswordPolicyReset'>
                            <ForcePasswordPolicyReset/>
                        </Route>
                        <Route>
                            <Redirect to={'/'}/>
                        </Route>
                    </Switch>
                }

            </ProvideForgotPasswordService>
        </BrowserRouter>

    } else {
        view = <></>
    }


    return (
        <>
            <CustomizedAlert/>
            {isLoading ? <GlobalLoader progressData={isLoading}/> : ''}
            {view}
        </>
    );
};

export default App;
