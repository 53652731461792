/**
 * contextBaseUri is the root of the server after the load balancer
 * Effectively is the path where the application shall look for the /api endpoints
 * @type {string|string}
 */
export const contextBaseUri = window.contextBaseUri || process.env.REACT_APP_CONTEXT_BASE_URI || "";
/**
 * contextPath is the contextBaseUri with / appended (for legacy reasons)
 * @type {string|string}
 */

const _contextPath = window.contextPath || process.env.REACT_APP_CONTEXT_PATH || "/";
export const contextPath = _contextPath.endsWith("/")?_contextPath : (_contextPath+"/");

/**
 * publicURL is the root url with trailing / for the UI elements.
 * E.g. if the server serves UI under /mgmt and there is a load balancer that serves
 * the application under /sancus
 * contextBaseUri shall be /sancus
 * contextPath shall be /sancus/
 * publicURL shall be /sancus/mgmt/
 * @type {string|string}
 */

const _publicURL= window.publicURL || process.env.PUBLIC_URL || "/";

export const publicURL = _publicURL.endsWith("/")?_publicURL : (_publicURL+"/");

console.log("publicURL=",publicURL);
console.log("contextPath=",contextPath);
console.log("contextBaseUri=",contextBaseUri);

// eslint-disable-next-line no-undef
__webpack_public_path__ = publicURL;

