/*
TODO this is "Working Draft" in specification check ths before production
 */

export function scrollToElement(element, options = {}) {
    console.log(`scrollToElement used with options`, element, options);
    element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
        , ...options
    });
}

/* Is element visible,  is on visible part of screen/view?
TODO this is "Working Draft" in specification check ths before production */
export function isElementIntoView(element) {
    const elemTop = element.getBoundingClientRect().top;
    const elemBottom = element.getBoundingClientRect().bottom;

    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    console.log(`isElementIntoView used with options`, element, ` isVisible =  ${isVisible}`);
    return isVisible;
}
