import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {blackButtonStyles, usersMgmtUrl, usersUrl} from "../variables";
import MultipleAutocomplete from "./MultipleAutocomplete";
import {AlertContext} from "../context/alert/alertContext";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function CreateUserDialog(props) {
    const classes = useStyles();
    const [dialogFieldsState, setDialogFieldsState] = useState(props.user || {});
    const [errorState, setErrorState] = useState({});
    const {showAlert} = useContext(AlertContext);
    const [drag, setDrag] = useState(false);
    const [ isAzureUser, setIsAzureUser ] = useState(false);

    useEffect(() => {
        if (props.user) {
            setDialogFieldsState(props.user);
            setIsAzureUser(props.user.azureUser);
        }
        setErrorState({});
    }, [props.user]);

    const handleDialogFieldChange = (event) => {
        event.persist();

        setDialogFieldsState((prevState) => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    };

    function createDialogSubmit(e) {
        e.preventDefault();

        if (!dialogFieldsState["groupIds"] || !dialogFieldsState["groupIds"].length) {
            return setErrorState({"groupIds": "At least 1 group must be set to the user"});
        }

        const submitData = {
            'id': dialogFieldsState["id"],
            'userId': dialogFieldsState["userId"],
            'firstName': dialogFieldsState["firstName"],
            'lastName': dialogFieldsState["lastName"],
            'email': dialogFieldsState["email"],
            'groupIds': dialogFieldsState["groupIds"],
            'isAzureUser': `${isAzureUser}`,
        };

        if (dialogFieldsState["photoDataUrl"]) {
            const idx = dialogFieldsState["photoDataUrl"].indexOf(';base64');
            submitData.photo_mimeType = dialogFieldsState["photoDataUrl"].substring("data:".length,idx);
            submitData.photo_base64 = dialogFieldsState["photoDataUrl"].substring(("data:" +  submitData.photo_mimeType + ';base64,').length);
        }

        axios({
            method: submitData["id"] ? 'PUT' : 'POST',
            url: submitData["id"] ? `${usersMgmtUrl}/${submitData["id"]}` : `${usersMgmtUrl}/`,
            data: submitData,
        })
            .then((response) => {
                    showAlert('Success!', 'success');
                    props.onSubmit();
                },
                error => {
                    console.log(error);
                    console.log(error.response);

                    if (error.response && error.response.status === 409) {
                        if (error.response.data === "id") {
                            setErrorState({"id": "User with such id already exists"});
                        } else if (error.response.data === "email") {
                            setErrorState({"email": "Email already exists"});
                        } else {
                            showAlert(error, 'error');
                        }

                    }else if (error.response && error.response.status === 400 && typeof error.response.data === 'object'){
                        setErrorState(error.response.data);
                    } else {
                        showAlert(error, 'error');
                    }
                });
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form autoComplete="off" onSubmit={createDialogSubmit}>
                <DialogTitle
                    id="alert-dialog-title">{dialogFieldsState.id ? "Update user" : "Create user"}</DialogTitle>
                <DialogContent>
                    <Grid container justify="center">
                        <Avatar src={dialogFieldsState["photoDataUrl"] || dialogFieldsState["imageURL"] || ''}
                                style={drag ? {backgroundColor: "black"} : {}}
                                className={classes.largeAvatar}

                                onDrop={event => {
                                    event.preventDefault();
                                    setDrag(false);

                                    if (event.dataTransfer.items[0]?.type?.indexOf("image/") === 0) {
                                        const file = event.dataTransfer.files[0];

                                        const toBase64 = file => new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onload = () => resolve(reader.result);
                                            reader.onerror = error => reject(error);
                                        });

                                        toBase64(file).then((base64) => {
                                            setDialogFieldsState((prevState) => {
                                                console.log('file: ', file);

                                                return {
                                                    ...prevState,
                                                    "photoDataUrl": base64,
                                                }
                                            });
                                        });
                                    }
                                }}

                                onDragOver={event => {
                                    event.preventDefault();
                                }}

                                onDragEnter={event => {
                                    setDrag(true)
                                }}

                                onDragExit={event => {
                                    setDrag(false)
                                }}

                        />
                    </Grid>

                    <TextField fullWidth={true} id={"userId"} name={"userId"} variant="outlined"
                               margin={"normal"} value={dialogFieldsState["id"] || dialogFieldsState["userId"]}
                               label={"Id"} required={true} onChange={handleDialogFieldChange}
                               disabled={dialogFieldsState["id"]}
                               error={!!errorState["id"]}
                               helperText={errorState["id"]}
                    />

                    <TextField fullWidth={true} id={"firstName"} name={"firstName"} variant="outlined"
                               margin={"normal"} value={dialogFieldsState["firstName"]}
                               label={"First Name"} required={true} onChange={handleDialogFieldChange}
                               error={!!errorState["firstName"]} helperText={errorState["firstName"]}
                    />

                    <TextField fullWidth={true} id={"lastName"} name={"lastName"} variant="outlined"
                               margin={"normal"} value={dialogFieldsState["lastName"]}
                               label={"Last Name"} required={true} onChange={handleDialogFieldChange}
                               error={!!errorState["lastName"]} helperText={errorState["lastName"]}

                    />

                    <TextField fullWidth={true} id={"email"} name={"email"} variant="outlined" margin={"normal"}
                               label={"Email"} value={dialogFieldsState["email"]}
                               required={true} type="email" onChange={handleDialogFieldChange}
                               error={!!errorState["email"]}
                               helperText={errorState["email"]}
                    />

                    <Grid container justify={'flex-start'} alignItems={'center'} spacing={1}>
                        <Grid item>
                            <Checkbox checked={isAzureUser} onChange={(evt, value) => {
                                setIsAzureUser(value);
                            }}/>
                        </Grid>
                        <Grid item>
                            <Typography variant={'subtitle1'}>Microsoft Azure user</Typography>
                        </Grid>
                    </Grid>


                    <FormControl fullWidth error={!!errorState["groupIds"]}>
                        <MultipleAutocomplete
                            label={"Groups"}
                            choices={props.groupsForAssign}
                            valueChangeCbk={(groupIds) => {
                                setDialogFieldsState((prevState) => {
                                    return {...prevState, "groupIds": groupIds}
                                });
                            }}

                            groupIds={dialogFieldsState["groupIds"]}/>
                        <FormHelperText id="component-helper-text">{errorState["groupIds"]}</FormHelperText>
                    </FormControl>

                </DialogContent>
                <DialogActions className={classes.buttonContainer}>
                    <Box pr={1}>
                        <Button onClick={props.onClose} color="primary">
                            Cancel
                        </Button>
                    </Box>
                    <Box p={2}>
                    <Button variant="contained" color="primary" autoFocus type="submit">
                        {dialogFieldsState.id ? "Update user" : "Add user"}
                    </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    )
}
