import React, {useContext, useEffect, useRef} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles/index';
import * as _ from 'lodash';
import Typography from '@material-ui/core/Typography/index';
import Grid from "@material-ui/core/Grid";
import {isElementIntoView, scrollToElement} from "../../utils";
import {LoggedUserContext} from "../../context/loggedUser/loggedUserContext";
import Avatar from "@material-ui/core/Avatar";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {tasksUrl} from "../../variables";
import {UsersContext} from "../../context/users/usersContext";
import {AlertContext} from "../../context/alert/alertContext";
import {GroupsContext} from "../../context/groups/GroupsContext";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        borderLeft: '4px solid transparent',
        boxShadow: '0 1px 1px 0 #e6e6e6',
        padding: 16,
        minHeight: 150,
        flex: "1 1 auto",
        cursor: "pointer",
        "&:hover": {
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.24)',
            backgroundColor: 'white',
            borderColor: theme.palette.primary.main
        }
    },
    activeCardContainer: {
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.24)',
        borderLeft: '3px solid ' + theme.palette.primary.main,
        backgroundColor: 'white',
    },
    taskTitle: {
        fontWeight: theme.palette.text.fontWeightBold,
    },
    assigneeTitle: {
        color: theme.palette.text.secondary
    },
    assigneeSubject: {
        color: theme.palette.text.secondary
    },
    createdAtTitle: {
        color: '#aaaaaa'
    },
    small_avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
    },
}));

export default function TaskCard({taskItem, isActive, onTaskClick, onTaskAssignCbk}) {
    const classes = useStyles();
    const taskChangeUrl = `${tasksUrl}/${taskItem.task.id}`;
    const {LoggedUserState} = useContext(LoggedUserContext);
    const {UsersState} = useContext(UsersContext);
    const {GroupsState} = useContext(GroupsContext);
    const {showAlert} = useContext(AlertContext);
    const [anchorElOfAssignUser, setAnchorElToAssignUser] = React.useState(null);
    const [anchorElOfAssignGroup, setAnchorElToAssignGroup] = React.useState(null);
    const cardRef = useRef();

    useEffect(() => {
        if (isActive) {
            if (!isElementIntoView(cardRef.current)) {
                scrollToElement(cardRef.current);
            }
        }
    }, [isActive]);


    /*  Assign task to User related code  */
    const handleOpenAssignUserMenu = (event) => {
        event.stopPropagation();
        setAnchorElToAssignUser(event.currentTarget);
    };

    const handleCloseAssignUserMenu = () => {
        setAnchorElToAssignUser(null);
    };

    const handleAssignUser = (event, userId) => {
        event.stopPropagation();

        axios({
            method: 'post',
            url: taskChangeUrl,
            params: {
                assign: userId,
            }
        })
            .then(response => {
                    onTaskAssignCbk();
                },
                error => showAlert(error, 'error')
            );
        setAnchorElToAssignUser(null);
    };

    const makeAssignGroupsAPICall = (groupIds) => {
        axios({
            method: 'post',
            url: taskChangeUrl,
            params: {
                assignGroupIds: "",
            },
            data: groupIds,
        })
            .then(response => {
                    onTaskAssignCbk();
                    setAnchorElToAssignGroup(null);
                },
                error => showAlert(error, 'error')
            );
    };

    const canUserAssignGroup = LoggedUserState.user.roles && (LoggedUserState.user.roles.indexOf('ADMIN') > -1 || LoggedUserState.user.roles.indexOf('MANAGER') > -1);

    let assigneeJsx = null;
    let groupJsx = null;
    let createdAtJsx = null;

    const assignedUser = taskItem.assignee || null;
    const assignedGroups = taskItem.candidateGroups;
    const task = taskItem.task;

    const [selectedGroups, setSelectedGroups] = React.useState(assignedGroups);

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const groupId = event.target.value;

        setSelectedGroups(prevGroups => {
            if (checked) {
                const addedGroup = GroupsState.find(g => g.id === groupId);

                return [...prevGroups, addedGroup]
            } else {
                return prevGroups.filter(g => g.id !== groupId)
            }
        });
    };

    /*  Assign task to group related code */
    const handleOpenAssignGroupMenu = (event) => {
        event.stopPropagation();
        setAnchorElToAssignGroup(event.currentTarget);
    };

    const handleCloseAssignGroupMenu = (event) => {
        event.stopPropagation();
        // after close submenu we compare  assignedGroups with selectedGroups and make API call if changes exists

        if (selectedGroups.length !== assignedGroups.length) {
            return makeAssignGroupsAPICall(_.map(selectedGroups, g => g.id));
        }

        let wasAssignedBefore = true;

        _.each(selectedGroups, (sg => {
            wasAssignedBefore = _.find(assignedGroups, g => g.id === sg.id);
            if (!wasAssignedBefore) {
                makeAssignGroupsAPICall(_.map(selectedGroups, g => g.id));
                return false;
            }
        }));

        if (wasAssignedBefore) {
            setAnchorElToAssignGroup(null);
        }
    };

    let candidatesForAssign = [];

    if (assignedGroups.length) {
        candidatesForAssign = assignedGroups.reduce((result, assignedGroup) => {
            if (assignedGroup && UsersState[assignedGroup.id]) {
                if (LoggedUserState.user.roles.indexOf('ADMIN') > -1 || LoggedUserState.user.groupIds.indexOf(assignedGroup.id) > -1) {
                    return result.concat(UsersState[assignedGroup.id]) || [];
                }
            }

            return result;
        }, []);
    } else {
        candidatesForAssign = UsersState['all'] || [];
    }

    if (isActive) {
        assigneeJsx = (
            <Grid style={{flex: "1 1 auto"}}>
                <Typography className={classes.assigneeTitle} variant="caption">
                    Assignee
                </Typography>


                <Grid container direction="row" justify="flex-start" alignItems="center">
                    {assignedUser ? (
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs>
                            <Avatar src={assignedUser.imageURL} className={classes.small_avatar}/>
                            <Typography variant="overline">
                                {(assignedUser.firstName || '')} {(assignedUser.lastName || '')}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs>
                            <Typography variant="overline">
                                Unassigned
                            </Typography>
                        </Grid>
                    )}

                    <ArrowDropDownIcon fontSize="large" onClick={handleOpenAssignUserMenu}/>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElOfAssignUser}
                        open={Boolean(anchorElOfAssignUser)}
                        onClose={handleCloseAssignUserMenu}
                    >
                        {candidatesForAssign.map((user, index) => (
                            <MenuItem key={user.id + index}
                                      onClick={(event) => handleAssignUser(event, user.id)}>
                                {user.firstName} {user.lastName}
                            </MenuItem>
                        ))}

                    </Menu>

                </Grid>
            </Grid>
        );

        groupJsx = (
            <Grid style={{flex: "1 1 auto"}}>
                <Typography className={classes.assigneeTitle} variant="caption">
                    Group
                </Typography>

                <Grid container direction="row" justify="flex-start" alignItems="center">

                    <Grid item xs>
                        {assignedGroups.length ? (
                            <Typography variant="overline">
                                {assignedGroups.map(g => g?.name).join(', ')}
                            </Typography>
                        ) : (
                            <Typography variant="overline">
                                Unassigned
                            </Typography>
                        )}
                    </Grid>

                    {canUserAssignGroup ?
                        <ArrowDropDownIcon fontSize="large" onClick={handleOpenAssignGroupMenu}/> : null}

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElOfAssignGroup}
                        open={Boolean(anchorElOfAssignGroup)}
                        onClose={handleCloseAssignGroupMenu}>

                        {GroupsState.map((group, index) => (
                            <MenuItem key={group.id} value={group}>
                                <Checkbox checked={selectedGroups.map(g => g?.name).indexOf(group.name) > -1}
                                          value={group.id} onChange={handleCheckboxChange}/>
                                <ListItemText primary={group.name}/>{}
                            </MenuItem>
                        ))}

                    </Menu>
                </Grid>
            </Grid>)

    } else {
        if (assignedUser) {
            assigneeJsx = (
                <Grid container direction="row" justify="flex-start" alignItems="center" style={{flex: "1 1 auto"}}>
                    <Avatar src={assignedUser.imageURL} className={classes.small_avatar}/>
                    <Typography className={classes.assigneeTitle} variant="overline">
                        {(assignedUser.firstName || '')} {(assignedUser.lastName || '')}
                    </Typography>
                </Grid>
            );

        } else {
            assigneeJsx =
                (<Grid container direction="row" justify="flex-start" alignItems="center" style={{flex: "1 1 auto"}}>
                    <Typography className={classes.assigneeTitle} variant="overline">
                        Unassigned
                    </Typography>
                </Grid>);

        }

        createdAtJsx = <Typography className={classes.createdAtTitle} variant="caption">
            {task.relativeTime}
        </Typography>
    }

    return (
        <Grid ref={cardRef} className={classes.cardContainer + (isActive ? ` ${classes.activeCardContainer}` : '')}
              onClick={() => onTaskClick && onTaskClick(taskItem)} container direction="column" justify="flex-start"
              alignItems="stretch">

            {createdAtJsx}

            <Grid item xs style={{flex: "1 1 auto"}}>
                <Typography variant="h6" className={classes.taskTitle}>
                    {task.name}
                </Typography>
            </Grid>

            {assigneeJsx}
            {groupJsx}

        </Grid>
    );
}
