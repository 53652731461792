import React, {useReducer} from 'react';
import {UsersContext} from './usersContext';
import {UsersReducer} from './usersReducer';
import {STORE_FETCHED_USERS} from '../types'

export const UsersState = ({children}) => {
    const initialState = {};
    const [state, dispatch] = useReducer(UsersReducer, initialState);

    function storeFetchedUsers(data) {
        console.log(`storeFetchedUsers triggered`, data);

        dispatch({
            type: STORE_FETCHED_USERS,
            payload: data
        })
    }

    return (
        <UsersContext.Provider value={{
            storeFetchedUsers,
            UsersState: state
        }}>
            {children}
        </UsersContext.Provider>
    )
};
