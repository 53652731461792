import React, { useContext } from 'react';
import { Route, Redirect} from 'react-router-dom';
import {LoggedUserContext} from "../context/loggedUser/loggedUserContext";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ children, ...rest }) => {
    const { LoggedUserState } = useContext(LoggedUserContext);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                LoggedUserState.isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};