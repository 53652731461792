import React, {useContext, useReducer} from 'react';
import {AlertContext} from './alertContext';
import {alertReducer} from './alertReducer';
import {SHOW_ALERT, HIDE_ALERT} from '../types'
import {LoggedUserContext} from "../loggedUser/loggedUserContext";

export const AlertState = ({children}) => {
    const initialState = {visible: false};
    const [state, dispatch] = useReducer(alertReducer, initialState);

    const {setUserLoggedOut} = useContext(LoggedUserContext);

    // here we can have hand made errors, just messages or Axios errors
    function handleError(error) {
        console.error(error);

        let message;
        let status;

        if (typeof error === 'string') {
            return error;
        }

        // this error handling code is from Axios site example
        // TODO rewrite, delete "extra" code after test server API
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            /*console.log('error.response.data', error.response.data);
            console.log('error.response.status', error.response.status);
            console.log('error.response.headers', error.response.headers);*/

            if (error.response.data){
                if (typeof error.response.data === 'string'){
                    message = error.response.data
                }else{
                    message = JSON.stringify(error.response.data);
                }
            }else{
                message = error.message;
            }

            status = error.response.status;
            window.myError = error;

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log('error.request', error.request);

        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            message = error.message;
            status = error.status;
        }

        // here is logged out logic for any 401 error
        if (status === 401) {
            setUserLoggedOut();
        }

        return message;
    }


    const showAlert = (textOrErrorObj, type = 'warning') => {
        let message = textOrErrorObj;

        if (type === 'error') {
            message = handleError(textOrErrorObj)
        }
        return new Promise((resolve,reject)=>{


            dispatch({
                type: SHOW_ALERT,
                payload: {text: message, type,resolve,reject}
            });
        })
    };

    const hideAlert = () => {
        dispatch({
            type: HIDE_ALERT
        });
    };

    return (
        <AlertContext.Provider value={{
            showAlert, hideAlert,
            alert: state
        }}>
            {children}
        </AlertContext.Provider>
    )
};
