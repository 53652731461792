import {LOGGED_OUT, LOGGED_IN} from '../types'

const handlers = {
    [LOGGED_IN]: (state, {payload}) => ({
        ...payload,
        isLoggedIn: true
    }),
    [LOGGED_OUT]: (state) => ({
        isLoggedIn: false,
    }),
    DEFAULT: state => state
};

export const loggedUserReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
