import React, {useContext, createContext} from 'react';
import axios from 'axios';
import {contextPath} from '../../contextPath'

const serviceFactory = () => {
    const client = axios.create({
        baseURL: `${contextPath}api/user-mgmt/forgot-password`,
    });

    /**
     * Api call to create otp for email.
     * @param email
     * @returns {Promise<void | never>}
     */
    const challengeUserOtp = async ({email}) => {
        const result = await client.post('/', {email});
        if(result.status === 204) {
            return 'alreadyActivated';
        }
        else {
            return result.data;
        }
    };

    const validateOtp = async ({otp}) => {
        const result = await client.post('/validate', {otp});
        console.log(result.data);
        return result.data;
    };

    /**
     * Api call to reset user password with otp.
     * @param otp
     * @param newPassword
     * @returns {Promise<boolean>}
     */
    const resetPassword = async ({otp, newPassword}) => {
        const result = await client.post('/resetPassword', {otp, newPassword});
        console.log(result.data);
        return result.data;
    };

    /**
     * Api call to force reset user password with otp.
     * @param otp
     * @param newPassword
     * @returns {Promise<boolean>}
     */
    const forceResetPassword = async ({usernameOrEmail, oldPassword, newPassword}) => {
        const result = await client.post('/forceResetPassword', {usernameOrEmail, oldPassword, newPassword});
        return result.data;
    };

    return {
        challengeUserOtp,
        resetPassword,
        validateOtp,
        forceResetPassword,
    };
};

const context = createContext();

export const ProvideForgotPasswordService = ({children}) => {
    const service = serviceFactory();

    return (
        <context.Provider value={service}>
            {children}
        </context.Provider>
    );
};

export const useForgotPasswordService = () => useContext(context);
