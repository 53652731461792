import {STORE_FETCHED_GROUPS} from '../types'

const handlers = {
    [STORE_FETCHED_GROUPS]: (state, {payload}) => ([...payload]),
    DEFAULT: state => state
};

export const GroupsReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
