import React from 'react';
import {makeStyles} from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import Paper from "@material-ui/core/Paper/index";
import Grid from "@material-ui/core/Grid/index";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        cursor: "pointer",
        "& h3": {
            fontWeight: 100,
        },
        "& p": {
            margin: 8,
            fontWeight: 500,
            //color: '#1f2124',
            textAlign: 'left'
        }
    },
    icon: {
        background: "#ffffff",
        borderRadius: "100px",
        margin: "8px",
        boxShadow: "0 0 43px 0 rgb(0 0 0 / 11%)",
        height: "60px",
        width: "60px"
    }
}));

export default function JourneyCard({cardData, onCardClick}) {
    const classes = useStyles();

    return (
        <Paper variant="outlined" square={true} className={classes.paper} id={cardData.name} onClick={() => {
            if (onCardClick) {
                onCardClick(cardData);
            }
        }}>
            <Grid container direction="row" alignItems="center">
                <Grid className={classes.icon} container direction="row" alignItems="center" justify="center">
                    <img height="30" src={cardData.img}/>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" color='textSecondary'>
                        {cardData.title}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
