// Camunda users reducer types
export const STORE_FETCHED_USERS = 'STORE_FETCHED_USERS';

// Camunda groups reducer types
export const STORE_FETCHED_GROUPS = 'STORE_FETCHED_GROUPS';

// logged user reducer types
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

// ALERT reducer types
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

// Camunda server configuration reducer types
export const STORE_SERVER_CONFIGURATION = 'STORE_SERVER_CONFIGURATION';


