import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
}));

export default function GlobalLoader({progressData}) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    function CircularProgressWithLabel(props) {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }


    function selectLoader() {
        if (typeof progressData === "boolean") {
            return <CircularProgress size={50}/>
        }

        if (typeof progressData === "string") {
            return (
                <Grid container direction="column" justify="center" alignItems="center">
                    <Box p={2} style={{ background: '#ffffff', borderRadius: '9px', boxShadow: '0px 0px 10px rgb(0 0 0 / 15%)'}}>
                        <Box p={2}>
                            <Typography variant="body1" color="textSecondary">
                                {progressData}
                            </Typography>
                        </Box>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Box p={2}>
                                <CircularProgress size={50}/>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            )
        }

        if (typeof progressData === "number") {
            return <CircularProgressWithLabel value={progressData} size={50}/>
        }

        if (typeof progressData === "object") {
            if ("message" in progressData && "percent" in progressData) {
                return (
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Typography variant="body2" color="textSecondary">
                            {progressData.message}
                        </Typography>
                        <CircularProgressWithLabel value={progressData.percent} size={50}/>
                    </Grid>
                )
            } else {
                return <CircularProgress size={50}/>
            }
        }

        return <CircularProgress size={50}/>
    }

    return (
        <Backdrop className={classes.backdrop} open={true}>
            {selectLoader()}
        </Backdrop>
    )
}
