import {contextPath} from "./contextPath";

export const usersUrl = `${contextPath}api/users`;
export const usersMgmtUrl = `${contextPath}api/user-mgmt`;
export const groupsUrl = `${contextPath}api/groups`;
export const tasksUrl = `${contextPath}api/taskmgmt`;
export const instanceUrl = `${contextPath}api/instance`;
export const notificationsUrl = `${contextPath}api/notifications`;
export const serverConfigUrl = `${contextPath}api/config/bas_portal`;
export const isIeBrowser = window.navigator.userAgent.indexOf('Trident') !== -1;

export const signInFormStyles = theme => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
});

export const blackButtonStyles = {
    '& [class*="MuiButton-contained"]:active': {
        background: '#212121',
        color: '#ffffff',
        boxShadow: 'none'
    },
    '& [class*="MuiButton-contained"]:hover': {
        background: '#212121',
        color: '#ffffff',
        boxShadow: 'none'
    },
    '& [class*="MuiButton-contained"]': {
        background: '#212121',
        color: '#ffffff',
        boxShadow: 'none'
    }
};
